import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL; 

export const getSquads = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}division`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const storeSquad = async (squad) => {
  const token = store.state.authToken;
  const response = await axios.post(`${API_URL}division`, squad, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } });
  return response.data;
};

export const getDetailSquad = async (id) => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}division/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const deleteSquad = async (id) => {
  const token = store.state.authToken;
  const response = await axios.delete(`${API_URL}division/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const updateSquad = async (id, data) => {
  const token = store.state.authToken;
  const response = await axios.put(`${API_URL}division/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};


