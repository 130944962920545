// Mengelompokkan import statements
import Vue from 'vue';
import VueRouter from 'vue-router';
import { logout } from '../services/authService';
import authMiddleware from '@/middleware/auth';

// Sisipkan import statements yang dipindahkan di atas

// login
import LoginView from '../views/LoginView.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import HomeView from '../views/HomeView.vue';

// dashboard
import DashboardView from '../views/DashboardView.vue';

// owner
import ListOwner from '../views/Owner/ListView.vue';
import DetailOwner from '../views/Owner/DetailView.vue';
import CreateOwner from '../views/Owner/CreateView.vue';
import EditOwner from '../views/Owner/EditView.vue';

// maker
import ListMaker from '../views/Maker/ListView.vue';
import DetailMaker from '../views/Maker/DetailView.vue';
import CreateMaker from '../views/Maker/CreateView.vue';
import EditMaker from '../views/Maker/EditView.vue';

// player
import ListPlayer from '../views/Player/ListView.vue';
import DetailPlayer from '../views/Player/DetailView.vue';
import CreatePlayer from '../views/Player/CreateView.vue';
import EditPlayer from '../views/Player/EditView.vue';

// payment
import ListPayment from '../views/PaymentManagement/ListView.vue';
import DetailPayment from '../views/PaymentManagement/DetailView.vue';
import CreatePayment from '../views/PaymentManagement/CreateView.vue';
import EditPayment from '../views/PaymentManagement/EditView.vue';

// billing & transaction
import BillingView from '../views/BillingView.vue';
import BillingPayView from '../views/PayBilling.vue';
import QuotaAddView from '../views/QuotaAdd.vue';
import QuotaView from '../views/QuotaView.vue';
import DetailQuota from '../views/DetailPayQuota.vue';

// voucher
import ListVoucher from '../views/Voucher/ListView.vue';
import DetailVoucher from '../views/Voucher/DetailView.vue';
import CreateVoucher from '../views/Voucher/CreateView.vue';
import EditVoucher from '../views/Voucher/EditView.vue';

// squad
import SquadView from '../views/Squad/SquadView.vue';
import DetailSquad from '../views/Squad/DetailSquad.vue';
import CreateSquad from '../views/Squad/CreateSquad.vue';
import EditSquad from '../views/Squad/EditSquad.vue';

// redeem
import ListRedeem from '../views/Redeem/RedeemView.vue';
import DetailRedeem from '../views/Redeem/DetailRedeem.vue';
import CreateRedeem from '../views/Redeem/CreateRedeem.vue';
import EditRedeem from '../views/Redeem/EditRedeem.vue';

// approval
import RewardsApproval from '../views/Approval/RewardApproval.vue';
import EditRewardApproval from '../views/Approval/EditRewardApproval.vue';
import ConsequenceApproval from '../views/Approval/ConsequenceApproval.vue';
import EditConsequenceApproval from '../views/Approval/EditConsequenceApproval.vue';
import CompensationApproval from '../views/Approval/CompensationApproval.vue';
import EditCompensationApproval from '../views/Approval/EditCompensationApproval.vue';
import PunishmentApproval from '../views/Approval/PunishmentApproval.vue';
import EditPunishmentApproval from '../views/Approval/EditPunishmentApproval.vue';

// rules
import ListRewards from '../views/Rules/RewardList.vue';
import CreateReward from '../views/Rules/CreateReward.vue';
import DetailReward from '../views/Rules/DetailReward.vue';
import EditReward from '../views/Rules/EditReward.vue';
import ConsequenceList from '../views/Rules/ConsequenceList.vue';
import CreateConsequence from '../views/Rules/CreateConsequence.vue';
import DetailConsequence from '../views/Rules/DetailConsequence.vue';
import EditConsequence from '../views/Rules/EditConsequence.vue';
import CompensationList from '../views/Rules/CompensationList.vue';
import CreateCompensation from '../views/Rules/CreateCompensation.vue';
import DetailCompensation from '../views/Rules/DetailCompensation.vue';
import EditCompensation from '../views/Rules/EditCompensation.vue';

// package
import ListPackage from '../views/Package/ListPackage.vue';
import DetailPackage from '../views/Package/DetailPackage.vue';
import CreatePackage from '../views/Package/CreatePackage.vue';
import EditPackage from '../views/Package/EditPackage.vue';

// profile
import ProfileView from '../views/ProfileView.vue'; // Added import for ProfileView
import ProfileEditView from '../views/ProfileEditView.vue'; // Added import for ProfileView
import ProfileEditPassword from '../views/ProfileEditPassword.vue'; // Added import for ProfileView

//discount
import DiscountList from '@/views/Discount/ListView.vue';
import CreateDiscount from '@/views/Discount/CreateView.vue';
import EditDiscont from '@/views/Discount/EditView.vue';



Vue.use(VueRouter);

// Mengelompokkan definisi routes
const routes = [
  { path: '/', name: 'Home', component: HomeView },
  { path: '/login', name: 'Login', component: LoginView },
  { path: '/forgotpassword', name: 'ForgotPassword', component: ForgotPassword },
  { path: '/dashboard', name: 'Dashboard', component: DashboardView, beforeEnter: authMiddleware },
  {
    path: '/billing',
    name: 'Billing',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'Billing', component: BillingView },
      { path: 'pay', name: 'BillingPay', component: BillingPayView },
      { path: 'detail', name: 'DetailBilling', component: DetailQuota, props: true }
    ]
  },
  {
    path: '/discount',
    name: 'discount',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'discount', component: DiscountList },
      // { path: 'detail/:id', name: 'DetailRedeem', component: DetailRedeem, props: true },
      { path: 'add', name: 'CreateDiscount', component: CreateDiscount },
      { path: 'edit/:id', name: 'Editdiscont', component: EditDiscont, props: true },
    ]
  },
  {
    path: '/quota',
    name: 'Quota',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'Quota', component: QuotaView },
      { path: 'add', name: 'QuotaAdd', component: QuotaAddView },
      // { path: 'detail/:id', name: 'DetailQuota', component: DetailQuota, props: true }
    ]
  },
  {
    path: '/squad',
    name: 'Squad',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListSquad', component: SquadView },
      { path: 'detail/:id', name: 'DetailSquad', component: DetailSquad, props: true },
      { path: 'add', name: 'CreateSquad', component: CreateSquad },
      { path: 'edit/:id', name: 'EditSquad', component: EditSquad, props: true },
    ]
  },
 
  {
    path: '/redeem',
    name: 'Redeem',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListRedeem', component: ListRedeem },
      { path: 'detail/:id', name: 'DetailRedeem', component: DetailRedeem, props: true },
      { path: 'add', name: 'CreateRedeem', component: CreateRedeem },
      { path: 'edit/:id', name: 'EditRedeem', component: EditRedeem, props: true },
    ]
  },
  {
    path: '/rules',
    name: 'Rules',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: 'rewards', name: 'ListRewards', component: ListRewards },
      { path: 'reward/create', name: 'CreateReward', component: CreateReward },
      { path: 'reward/detail/:id', name: 'DetailReward', component: DetailReward, props: true },
      { path: 'reward/edit/:id', name: 'EditReward', component: EditReward, props: true },
      { path: 'consequence', name: 'ListConsequence', component: ConsequenceList },
      { path: 'consequence/create', name: 'CreateConsequence', component: CreateConsequence },
      { path: 'consequence/detail/:id', name: 'DetailConsequence', component: DetailConsequence, props: true },
      { path: 'consequence/edit/:id', name: 'EditConsequence', component: EditConsequence, props: true },
      { path: 'compensation', name: 'Compensation', component: CompensationList },
      { path: 'compensation/create', name: 'CreateCompensation', component: CreateCompensation },
      { path: 'compensation/detail/:id', name: 'DetailCompensation', component: DetailCompensation, props: true },
      { path: 'compensation/edit/:id', name: 'EditCompensation', component: EditCompensation, props: true },
    ]
  },
  {
    path: '/approval',
    name: 'Approval',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: 'rewards', name: 'RewardsApproval', component: RewardsApproval },
      { path: 'reward/edit/:id', name: 'EditRewardApproval', component: EditRewardApproval, props: true },
      { path: 'consequence', name: 'ConsequenceApproval', component: ConsequenceApproval },
      { path: 'consequence/edit/:id', name: 'EditConsequenceApproval', component: EditConsequenceApproval, props: true },
      { path: 'compensation', name: 'CompensationApproval', component: CompensationApproval },
      { path: 'compensation/edit/:id', name: 'EditCompensationApproval', component: EditCompensationApproval, props: true },
      { path: 'punishment', name: 'PunishmentApproval', component: PunishmentApproval },
      { path: 'punishment/edit/:id', name: 'EditPunishmentApproval', component: EditPunishmentApproval, props: true },
    ]
  },
  {
    path: '/voucher',
    name: 'Voucher',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListVoucher', component: ListVoucher },
      { path: 'detail', name: 'DetailVoucher', component: DetailVoucher },
      { path: 'add', name: 'CreateVoucher', component: CreateVoucher },
      { path: 'edit', name: 'EditVoucher', component: EditVoucher }
    ]
  },
  {
    path: '/owner',
    name: 'Owner',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListOwner', component: ListOwner },
      { path: 'detail/:id', name: 'DetailOwner', component: DetailOwner, props: true },
      { path: 'add', name: 'CreateOwner', component: CreateOwner },
      { path: 'edit/:id', name: 'EditOwner', component: EditOwner, props: true }
    ]
  },
  {
    path: '/maker',
    name: 'Maker',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListMaker', component: ListMaker },
      { path: 'detail/:id', name: 'DetailMaker', component: DetailMaker, props:true },
      { path: 'add', name: 'CreateMaker', component: CreateMaker },
      { path: 'edit/:id', name: 'EditMaker', component: EditMaker, props:true }
    ]
  },
  {
    path: '/package',
    name: 'Package',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListPackage', component: ListPackage },
      { path: 'detail/:id', name: 'DetailPackage', component: DetailPackage, props:true },
      { path: 'add', name: 'CreatePackage', component: CreatePackage },
      { path: 'edit/:id', name: 'EditPackage', component: EditPackage, props:true }
    ]
  },
  {
    path: '/player',
    name: 'Player',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListPlayer', component: ListPlayer },
      { path: 'detail/:id', name: 'DetailPlayer', component: DetailPlayer, props: true },
      { path: 'add', name: 'CreatePlayer', component: CreatePlayer },
      { path: 'edit/:id', name: 'EditPlayer', component: EditPlayer, props: true }
    ]
  },
  {
    path: '/profile',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'Profile', component: ProfileView },
      { path: 'edit', name: 'ProfileEdit', component: ProfileEditView },
      { path: 'edit/password', name: 'ProfileEditPassword', component: ProfileEditPassword }
    ]
  },
  {
    path: '/payment',
    name: 'Payment',
    component: { render (c) { return c('router-view') } },
    beforeEnter: authMiddleware,
    children: [
      { path: '', name: 'ListPayment', component: ListPayment },
      { path: 'detail/:id', name: 'DetailPayment', component: DetailPayment, props: true },
      { path: 'add', name: 'CreatePayment', component: CreatePayment },
      { path: 'edit/:id', name: 'EditPayment', component: EditPayment, props: true }
    ]
  },
  { path: '/logout', name: 'Logout', beforeEnter: (to, from, next) => { logout(); next('/login'); } }
];

// Mengelompokkan pembuatan router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;