<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Confirmation Pay Billing</h3>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <form> -->
            <div class="form-group">
              <label for="invoice">Payment Destination Account</label>
              <div class="input-">
                <span class="mb-0">BCA - PT Lightech Business Automation</span>
                <br>
                <span>Account Number: 5892 000 000</span>
                <!-- <input type="text" class="form-control bg-black" id="invoice" v-model="invoice" > -->
              </div>
            </div>
            <div class="form-group">
              <label for="invoice">Invoice Number</label>
              <div class="input-group">
                <span>{{ invoice }}</span>
                <!-- <input type="text" class="form-control bg-black" id="invoice" v-model="invoice" > -->
              </div>
            </div>
            <div class="form-group">
              <label for="totalTagihan">Total bill</label>
              <div class="input-group">
                <!-- <div class="input-group-append">
                    <span class="input-group-text">Rp</span>
                  </div> -->
                <span>RP. {{ formatPrice(amount_payment_proof) }}</span>
                <!-- <input type="number" class="form-control bg-black" id="totalTagihan" v-model="totalTagihan" > -->
              </div>
            </div>
            <div class="form-group">
              <label for="paymentDate">Payment Date</label>
              <div class="input-group">
                <input
                  type="date"
                  class="form-control"
                  id="paymentDate"
                  :v-model="payment_date"
                />
              </div>
            </div>
            <!-- <div class="form-group">
                <label for="paymentAmount">Payment Amount</label>
                <div class="input-group">
                  <div class="input-group-append">
                    <span class="input-group-text">Rp</span>
                  </div>
                  <input type="number" class="form-control" id="paymentAmount" v-model="paymentAmount" required>
                </div>
              </div> -->
            <div class="form-group">
              <label for="bankSender">Sending Bank</label>
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  id="account_number"
                  v-model="bank"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <label for="senderName">Account Number</label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  id="account_number"
                  v-model="bank_account_name"
                  required
                />

                <!-- <span>3298430898</span> -->
              </div>
            </div>
            <div class="form-group">
              <label for="paymentProof">Upload Proof of Payment</label>
              <div class="input-group">
                <input
                  type="file"
                  class="form-control"
                  id="paymentProof"
                  @change="handleFileUpload($event)"
                  :v-model="payment_proof"
                  required
                />
              </div>
            </div>
            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button  @click="paymanual"
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div
                  class="spinner-border text-warning me-3"
                  :style="{ width: '14px', height: '14px' }"
                  role="status"
                  v-if="loading"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import config from "../config/config";
import store from "../store";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "BillingView",
  data() {
    return {
      companyOptions: [],
      transaction: [],
      invoice: sessionStorage.getItem("noinvoice"),
      amount_payment_proof: sessionStorage.getItem("amount"),
      payment_date: moment().format("YYYY-MM-DD"),
      payment_proof:"",
      paymentAmount: "",
      bank: "",
      bank_account_name:"",
      billing_id:sessionStorage.getItem("billing_id"),
      senderName: "",
      paymentProof: "",
      currentPage: 1,
     itemsPerPage: 10,
      loading: false,
    };
  },
  methods: {
    handleFileUpload(event) {
      this.payment_proof = event.target.files[0];
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(date) {
      return moment(date).format("DD MMM YYYY");
    },
    paymanual() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("billing_id", this.billing_id);
      formData.append("payment_proof", this.payment_proof);
      formData.append("payment_date",this.payment_date);
      formData.append("amount_payment_proof",this.amount_payment_proof);
      formData.append("bank",this.bank);
      formData.append("bank_account_name",this.bank_account_name);
      formData.append("type", "manual");
      formData.append("_method", "POST");

      axios
        .post(`${API_URL}billing/confirm/payment-manual`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Successfully made manual payment method",
          });
          this.$router.go(-3);
          console.log(resp);
        })
        .catch((err) => {
          console.log(err.response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
        });
    },
    back() {
      this.$router.go(-1);
    },
  
  },
};
</script>
