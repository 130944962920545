<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Create New Payment</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form class="forms-sample" method="post" @submit.prevent="submitForm">
            <div class="form-group row">
              <div class="col">
                <label>Payment Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                  v-model="paymentStore.paymentName"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Price / Person</label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputUsername1"
                  v-model="paymentStore.price"
                />
              </div>
              <div class="col">
                <label>Status</label>
                <select
                  class="form-control"
                  :style="{ width: '100%' }"
                  v-model="paymentStore.status"
                >
                  <option>Active</option>
                  <option>Inactive</option>
                </select>
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div
                  class="spinner-border text-warning me-3"
                  :style="{ width: '14px', height: '14px' }"
                  role="status"
                  v-if="loading"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storePayment } from "../../services/paymentService";
import Swal from "sweetalert2";

export default {
  data: () => ({
    paymentStore: {
      paymentName: "",
      price: "",
      status: "",
    },
    loading: false,
  }),
  methods: {
    async submitForm() {
      this.loading = true;
      try {
        await storePayment(this.paymentStore);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Game owner created successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.push({ name: "ListPayment" });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.errors[0],
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      this.companyLogo = event.target.files[0];
    },
  },
};
</script>
