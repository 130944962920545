import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL; 

export const getCompensation = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}compensation`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const storeCompensation = async (compensation) => {
  const token = store.state.authToken;
  const response = await axios.post(`${API_URL}compensation`, compensation, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } });
  return response.data;
};

export const getDetailCompensation = async (id) => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}compensation/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const deleteCompensation = async (id) => {
  const token = store.state.authToken;
  const response = await axios.delete(`${API_URL}compensation/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const updateCompensation = async (id, data) => {
  const token = store.state.authToken;
  const response = await axios.put(`${API_URL}compensation/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};


