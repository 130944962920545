<template>
       <div class="row ">
       <div class="page-header"> <h3 class="page-title"> Update Voucher </h3>  </div>
        
       
       <div class="col-12 grid-margin">
           <div class="card">
             <div class="card-body"> 
 
             <form class="forms-sample" method="post" action="https://dashboard.gamiteam.app/voucher">
                     
                     <div class="form-group row"> 
                         <div class="col-6">
                           <label>Voucher Title</label>
                           <input type="text" value="Voucher Name 26" class="form-control" id="exampleInputUsername1">
                         </div>
                         <div class="col-6">
                           <label>Voucher Code</label>
                           <input type="text" value="GMT154SS" class="form-control" id="exampleInputUsername1">
                         </div> 
                     </div>
 
                     
                     
                     <div class="form-group row"> 
                       <div class="col-6">
                           <label>Minimum Transaction</label>
                           <input type="number" value="100.000" class="form-control" id="exampleInputUsername1">
                         </div>
                         
                         
                         
                         <div class="col-6">
                           <label>Quantity</label>
                           <input type="number" value="10" class="form-control" id="exampleInputUsername1">
                         </div>
                     </div>
                     
                     <div class="form-group row"> 
                       <div class="col-6">
                           <label>Discount Type</label>
                           <select class="form-control">
                             <option>Percentage</option>
                             <option>Value</option>
                           </select>
                         </div>
                         
                         <div class="col-6">
                           <label>Discount Amount</label>
                           <input type="number" value="50.000" class="form-control" id="exampleInputUsername1">
                         </div>
                     </div>
                      
                     
 
                      
  
 
                       
                      
                      
                      
                     <div class="form-group text-right ">
                     <button type="button" @click="back()" class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"> <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i> Back</button>
                       <button type="submit" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"> <i class="mdi mdi-check-all  btn-icon-prepend"></i> Update Data </button>
                     </div>
                   </form> 
 
               </div> 
             </div>
           </div>
         </div>
</template>

<script>
export default {
  name: "EditVoucher",
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
