import axios from "axios";
import config from "../config/config";
import store from "../store";
import Swal from 'sweetalert2';

const API_URL = config.API_URL; 

export const login = async (data) => {
    const email = data.email;
    const password = data.password;
    const response = await axios.post(`${API_URL}auth/login`, { email, password });
    return response.data;
};

export const logout = async () => {
    try {
        const token = store.state.authToken;
        if (token) {
            await axios.post(`${API_URL}auth/logout`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        }
        store.commit('setAuthToken', null);
        store.commit('setTokenType', null);
        store.commit('setUser', null);
        // Show success alert
        Swal.fire({
            icon: 'success',
            title: 'Logged out',
            text: 'You have been logged out successfully.',
            confirmButtonColor: '#0090e7',
        });
    } catch (error) {
        console.error('Error logging out:', error);
    }
};

export const forgotPasswordAPI = async (email) => {
    const response = await axios.post(`${API_URL}forgot-password`, {email})
    return response.data;
}

export const getProfile = async (id) => {
    try {
        const token = store.state.authToken;
        const response = await axios.get(`${API_URL}user/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching profile:', error);
        throw error;
    }
};

export const updateProfile = async (id, data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}user/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}


export const updateProfilePassword = async (id, data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}auth/change-password`, data, { headers: { Authorization: `Bearer ${token}` } });
    return response.data;
};