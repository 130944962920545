import axios from "axios";
import config from "../config/config";

const API_URL = config.API_URL; 

export const storePayment = async (data) => {
    const response = await axios.post(`${API_URL}payment/store`, data);
    return response.data;
}

export const getPayment = async () => {
    const response = await axios.get(`${API_URL}payment`);
    return response.data;
}

export const getDetailPayment = async (id) => {
    const response = await axios.get(`${API_URL}payment/${id}`);
    return response.data;
}

export const deletePayment = async (id) => {
    const response = await axios.delete(`${API_URL}payment/delete/${id}`);
    return response.data;
}

export const updatePayment = async (id, data) => {
    const response = await axios.post(`${API_URL}payment/update/${id}`, data);
    return response.data;
}

