<template>
    <div class="row">
        <div class="page-header">
            <h3 class="page-title">Create New Squad</h3>
        </div>

        <div class="col-12 grid-margin">
            <div class="card">
                <div class="card-body">
                    <form class="forms-sample" method="post" @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="form-group row">
                            <div class="col">
                                <label for="nameInput">Name</label>
                                <input id="nameInput" type="text" class="form-control" v-model="ruleStore.name" />
                            </div>
                        </div>

                        <div class="form-group text-right">
                            <button type="button" @click="back"
                                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3" :disabled="loading">
                                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                                Back
                            </button>
                            <button type="submit" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                                :disabled="loading">
                                <div class="spinner-border text-warning me-3" :style="{ width: '14px', height: '14px' }"
                                    role="status" v-if="loading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import "dropify";
import { storeSquad } from "../../services/squadService";
import Swal from 'sweetalert2';

export default {
    data: () => ({
        ruleStore: {
            name: '',
        },
        loading: false,
    }),
    methods: {
        back() {
            this.$router.go(-1);
        },
        submitForm() {
            this.loading = true;
            try {
                storeSquad(this.ruleStore);

                Swal.fire('Success', 'Squad created successfully', 'success');

                this.$router.push('/squad');
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style>
ul.custom-list {
    list-style-type: none;
    padding: 0;
}

ul.custom-list li {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

ul.custom-list li input[type="checkbox"],
ul.custom-list li input[type="radio"] {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    transform: scale(1);
}

ul.custom-list li span {
    font-size: 1.2em;
}

.grouping {
    background-color: #282e3e;
    padding: 10px;
    border-radius: 10px;
}
</style>

