var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12 grid-margin"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],staticClass:"col-md-2",staticStyle:{"float":"right","background-color":"transparent","border-color":"aliceblue","color":"aliceblue"},attrs:{"placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"keyup":_vm.getBilingAdminsearch,"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._m(1)]),_c('table',{staticClass:"table"},[_vm._m(2),_c('tbody',[_vm._l((_vm.paginatedpunishmentApproval),function(data,index){return _c('tr',{key:data.id},[_c('td',[_vm._v(_vm._s(index + 1 + (_vm.currentPage - 1) * _vm.itemsPerPage))]),_c('td',[_c('router-link',{attrs:{"to":{
                      name: 'EditPunishmentApproval',
                      params: { id: data.id },
                    }}},[_vm._v(" "+_vm._s(data.user.name)+" ")])],1),_c('td',[_vm._v(_vm._s(data.wrong_doing))]),_c('td',[(data.status === 'pending')?_c('span',{staticClass:"badge badge-warning"},[_vm._v("Pending")]):_vm._e(),(data.status === 'approved')?_c('span',{staticClass:"badge badge-success"},[_vm._v("Approved")]):_vm._e(),(data.status === 'rejected')?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Rejected")]):_vm._e()])])}),(_vm.punishmentApproval.length === 0)?_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"7"}},[_vm._v("No data available")])]):_vm._e()],2)])]),(_vm.punishmentApproval.length > 0)?_c('div',[_c('PaginationComponents',{attrs:{"totalItems":_vm.punishmentApproval.length,"itemsPerPage":_vm.itemsPerPage,"currentPage":_vm.currentPage},on:{"page-changed":_vm.onPageChanged}})],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-header"},[_c('h3',{staticClass:"page-title"},[_vm._v("Punishment Approval List")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",staticStyle:{"border-color":"aliceblue"}},[_c('i',{staticClass:"mdi mdi-magnify"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("No")]),_c('th',[_vm._v("Player")]),_c('th',[_vm._v("Violation")]),_c('th',[_vm._v("Status")])])])
}]

export { render, staticRenderFns }