import axios from "axios";
import config from "../config/config";
import store from '../store';
import Swal from 'sweetalert2'; // Import SweetAlert2

const API_URL = config.API_URL;

export default async function (to, from, next) {
    try {
        const token = store.state.authToken;
        if (!token) {
            window.location.href = '/login'; // Redirect to login if token is not present
            return;
        }

        const response = await axios.get(`${API_URL}auth/me`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        const userData = response.data.data;
        if (userData.role !== 'superadmin' && userData.role !== 'owner') {
            localStorage.removeItem('tokenType');
            localStorage.removeItem('authToken');
            Swal.fire({
            icon: 'error',
                title: 'Access Denied',
                text: 'You do not have permission to access this page.',
            })
        } else {
            if (response.status === 200) {
                // Token is valid, allow access to the route
                store.dispatch('updateUser', JSON.stringify(response.data));
                next(await response.data);
            } else {
                // Token is invalid, redirect to login
                window.location.href = '/login';
            }
        }

        
    } catch (error) {
        // Handle any errors by redirecting to login
        window.location.href = '/login';
    }
}