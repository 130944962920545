<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Create New Discount</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            method="post"
            @submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <div class="form-group row">
              <div class="col">
                <label for="nameInput">Title</label>
                <input
                  id="nameInput"
                  type="text"
                  class="form-control"
                  v-model="model.title"
                />
              </div>
              <div class="col">
                <label for="dobInput">Expired at</label>
                <input
                  id="dobInput"
                  type="date"
                  class="form-control"
                  v-model="model.expired_at"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="emailInput">Description</label>
                <input
                  id="emailInput"
                  type="text"
                  class="form-control"
                  v-model="model.desc"
                />
              </div>
              <div class="col">
                <label for="phone_numberInput">Type</label>
                <select name="" v-model="model.type" class="form-control" id="">
                  <option value="">Select Type</option>
                  <option value="percent">%</option>
                  <option value="flat">Rp</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="passwordInput">Value</label>
                <input
                  id="passwordInput"
                  type="number"
                  class="form-control"
                  v-model="model.value"
                />
              </div>
              <div class="col">
                <label for="passwordInput">Limit User</label>
                <input
                  id="passwordInput"
                  type="number"
                  class="form-control"
                  v-model="model.limit_user"
                />
              </div>
              <div class="col">
                <label for="">Code</label>
                <input
                  id=""
                  type="text"
                  class="form-control"
                  v-model="model.code"
                />
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
             
               
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';
import { storeDiscont } from "../../services/discountServices";

export default {
  data: () => ({
    model: {
      title: "",
      desc: "",
      type: "",
      value: 0,
      code: "",
      limit_user:0,
      expired_at: "",
    },
    squadOptions: [],
    companyOptions: [],
    loading: false,
  }),

  methods: {
    async submitForm() {
            this.loading = true;
            try {
                await storeDiscont(this.model);
                
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Discount  created successfully',
                    confirmButtonColor: '#0090e7',
                });

                this.$router.push({ name: 'discount' });
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: error.response.data.errors[0],
                    confirmButtonColor: '#0090e7',
                });
            } finally {
                this.loading = false;
            }
        },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
