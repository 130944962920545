<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Create New Game Maker</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            method="post"
            @submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <div class="form-group row">
              <div class="col">
                <label for="nameInput">Maker Name</label>
                <input
                  id="nameInput"
                  type="text"
                  class="form-control"
                  v-model="makerStore.name"
                />
              </div>
              <div class="col">
                <label for="dobInput">Date Of Birth</label>
                <input
                  id="dobInput"
                  type="date"
                  class="form-control"
                  v-model="makerStore.dob"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="emailInput">Email Address</label>
                <input
                  id="emailInput"
                  type="text"
                  class="form-control"
                  v-model="makerStore.email"
                />
              </div>
              <div class="col">
                <label for="phone_numberInput">Phone Number</label>
                <input
                  id="phone_numberInput"
                  type="text"
                  class="form-control"
                  v-model="makerStore.phone_number"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="passwordInput">Set Password</label>
                <input
                  id="passwordInput"
                  type="password"
                  class="form-control"
                  v-model="makerStore.password"
                />
              </div>
              <div class="col">
                <label for="squadSelect">Squad</label>
                <select
                  id="squadSelect"
                  class="form-control"
                  :style="{ width: '100%' }"
                  v-model="makerStore.division_id"
                >
                  <option
                    v-for="option in squadOptions"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6 col-sm-12">
                <div class="row">
                  <div class="col-md-6">
                    <label for="userPhotoInput">User Photo</label>
                    <input
                    type="file"
                    class="dropify"
                    id="userPhotoInput"
                    @change="handleFileChange"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div class="spinner-border text-warning me-3" :style="{width: '14px', height: '14px'}" role="status" v-if="loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "dropify";
import { getCompany } from "../../services/makerService";
import { storeMaker } from "../../services/makerService";
import { getSquad } from "../../services/makerService";
import Swal from 'sweetalert2';

export default {
  data: () => ({
    makerStore: {
      company_id: null,
      name: "",
      email: "",
      phone_number: "",
      password: "",
      division_id: null,
      profile_picture: null,
      role: "maker",
    },
    squadOptions: [],
    companyOptions: [],
    loading: false,
  }),
  async mounted() {
    await this.fetchData();
    this.makerStore.company_id = this.companyOptions.length > 0 ? this.companyOptions[0].id : null;
    this.makerStore.division_id = this.squadOptions.length > 0 ? this.squadOptions[0].id : null;
    this.companyid = JSON.parse(this.$store.state.user).data.company_id;

    $(".dropify").dropify({
      messages: {
        default: "Drag and drop a file here or click",
        replace: "Drag and drop or click to replace",
        remove: "Remove",
        error: "Ooops, something wrong happened.",
      },
      error: {
        fileSize: "The file size is too big ({{ value }} max).",
        minWidth: "The image width is too small ({{ value }}}px min).",
        maxWidth: "The image width is too big ({{ value }}}px max).",
        minHeight: "The image height is too small ({{ value }}}px min).",
        maxHeight: "The image height is too big ({{ value }}}px max).",
        imageFormat: "The image format is not allowed ({{ value }} only).",
      },
      tpl: {
        wrap: '<div class="dropify-wrapper"></div>',
        loader: '<div class="dropify-loader"></div>',
        message:
          '<div class="dropify-message"><span class="file-icon" /> <p>{{ default }}</p></div>',
        preview:
          '<div class="dropify-preview"><span the "dropify-render"></span><div class="dropify-infos"></div></div>',
        filename:
          '<p class="dropify-filename"><span the "file-icon" /> {{ name }}</p>',
        file: '<div class="dropify-file"></div>',
        clearButton:
          '<button type="button" class="dropify-clear">Remove</button>',
        errorLine: '<p class="dropify-error">{{ error }}</p>',
        errorsContainer:
          '<div class="dropify-errors-container"><ul></ul></div>',
      },
    });
  },
  methods: {
    async fetchData() {
      await getCompany().then((response) => {
        if (response.length == 0) {
          this.companyOptions = []
        } else {
            response.forEach((item) => {
            this.companyOptions.push(item);
          });
        }
      });

      await getSquad().then((response) => {
        if (response.data.length == 0) {
          this.squadOptions = [];
          Swal.fire({
            icon: "warning",
            title: "No Squads Available",
            text: "You have no squad available.",
            confirmButtonColor: "#0090e7",
          }).then(() => {
            this.back();
          });
        } else {
          response.data.forEach((item) => {
            this.squadOptions.push(item);
          });
        }
      });
    },
    async submitForm() {
      this.loading = true;
      try {
        await storeMaker(this.makerStore);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Game maker created successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.push({ name: "ListMaker" });
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        this.makerStore.profile_picture = event.target.files[0];
      } else {
        this.makerStore.profile_picture = null; // Ensure it's null if no files are selected
      }
    },
  },
};
</script>

