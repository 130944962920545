import axios from "axios";
import config from "../config/config";
import store from "../store"

const API_URL = config.API_URL; 

export const storeOwner = async (data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}company`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const getOwner = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}company`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const getDetailOwner = async (id) => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}company/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const updateOwner = async (id, data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}company/${id}?_method=PUT`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const deleteOwner = async (id) => {
    const token = store.state.authToken;
    const response = await axios.delete(`${API_URL}company/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}