<template>
  <div class="container-fluid">
    <div class="content-wrapper">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="card login">
            <div class="card-header text-center">
              <img
                width="190"
                class="mx-auto"
                src="../assets/images/logo-big.svg"
                alt=""
              />
            </div>
            <div class="card-body px-5 pt-5">
              <h5 class="card-title mb-2 text-center">Reset Password</h5>
              <br />
              <form class="form-login" @submit.prevent="submit">
                <div class="form-group row">
                  <div class="col">
                    <label for="currentPassword">Current Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="currentPassword"
                      v-model="userStore.current_password"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col">
                    <label for="newPassword">New Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="newPassword"
                      v-model="userStore.new_password"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col">
                    <label for="confirmNewPassword">Confirm New Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="confirmNewPassword"
                      v-model="userStore.confirm_new_password"
                    />
                    <div
                      v-if="
                        userStore.new_password !==
                        userStore.confirm_new_password
                      "
                      class="text-danger"
                      role="alert"
                    >
                      Passwords do not match.
                    </div>
                  </div>
                </div>

                <div class="text-center mb-3">
                  <button
                 
                    type="submit"
                    class="btn btn-outline-primary btn-icon-text mt-2 px-5 py-2"
                    :disabled="
                      loading ||
                      userStore.new_password !== userStore.confirm_new_password
                    "
                  >
                    <div
                      class="spinner-border text-warning me-3"
                      :style="{ width: '14px', height: '14px' }"
                      role="status"
                      v-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    Reset Password
                  </button>
                  <br /><br />
                  <router-link to="/login" type="button" class="text-warning"
                    >Back to Login Page</router-link
                  >
                  <h1>{{ token }}</h1>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { login } from "@/services/authService";
// import { forgotPasswordAPI } from "@/services/authService";
// import Swal from "sweetalert2";
import axios from "axios";
// import { updateProfilePassword } from "../services/authService";
import config from "../config/config";
import Swal from "sweetalert2";
// import store from "../store";

export default {
  name: "ForgotPassword",
  data: () => ({
    userStore: {
      email: "",
      token: "",
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    // id: JSON.parse(store.state.user).data.id,
    config,
    loading: false,
    token: "",
  }),
  methods: {
    submit() {
      const API_URL = config.API_URL;
      const token = this.userStore.token;
      let formData = new FormData();
      formData.append("email", this.userStore.email);
      formData.append("token", this.userStore.token);
      formData.append("new_password", this.userStore.new_password);
     
      formData.append("_method", "POST");

      
      axios
        .post(`${API_URL}auth/reset-password`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);

          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Password updated successfully",
            confirmButtonColor: "#0090e7",
          });

          // window.location = "/login";
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Error",
          });
          console.log(err.response);
        });
    },
    // async submitForm() {
    //   this.loading = true;
    //   try {
    //     const response = await updateProfilePassword(this.userStore);
    //     console.log(response);

    //     Swal.fire({
    //       icon: "success",
    //       title: "Success",
    //       text: "Password updated successfully",
    //       confirmButtonColor: "#0090e7",
    //     });

    //     window.location = "/login";
    //   } catch (error) {
    //     Swal.fire({
    //       icon: "error",
    //       title: "Oops...",
    //       text: error.response.data.errors[0],
    //       confirmButtonColor: "#0090e7",
    //     });
    //   } finally {
    //     this.loading = false;
    //   }
    // },
  },
  mounted() {
    this.userStore.email = this.$route.query.email;
    this.userStore.token = this.$route.query.token;
  },
};
</script>
