<template>
  <div>
    <div class="row">
      <div class="page-header">
        <h3 class="page-title">Rules Details</h3>
      </div>

      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <form class="forms-sample" method="post" action="https://dashboard.gamiteam.app/player">
              <div class="form-group row">
                <div class="col-6">
                  <label for="exampleInputUsername1">Rule Name</label>
                  <div class="py-2">{{ rule.name }}</div>
                </div>
                <div class="col-6">
                  <label for="exampleInputUsername1">Description</label>
                  <div class="py-2">{{ rule.description || '-' }}</div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-6">
                  <label for="exampleInputUsername1">Input Date</label>
                  <div class="py-2">{{ formatDate(rule.created_at) }}</div>
                </div>
                <div class="col-6">
                  <label>Rule Appeareance</label>
                  <div class="py-2 text-camelcase">{{ rule.repeatable_rule ? rule.repeatable_rule.repeat_type : '-' }}</div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-6">
                  <label for="exampleInputUsername1">Unit - Value</label>
                  <div class="py-2">{{ rule.gold ? 'Gold - ' + rule.gold : (rule.non_gold ? 'Non Gold - ' + rule.non_gold : '0 Gold') }}</div>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-6">
                  <label for="exampleInputUsername1">Assignation</label>
                  <div class="py-2" v-if="rule.assignees && rule.assignees.length > 0">
                    <span v-if="rule.assignees[0].user != null">Specific Player</span>
                    <span v-else>Specific Division</span>
                    <div class="py-2">
                      <ol>
                        <li v-for="assignee in assignees" :key="assignee">{{ assignee }}</li>
                      </ol>
                    </div>
                  </div>
                  <div class="py-2" v-else>
                    All Players
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 me-3" @click="back"
                  :disabled="loading">
                  <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                  Back
                </button>
                <router-link :to="{ name: 'EditReward' }"
                  class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2 me-3">
                  <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Edit
                  Data</router-link>
                <a class="btn btn-outline-danger btn-icon-text mt-2 px-5 py-2" @click="confirmDelete">
                  <i class="mdi mdi-delete-outline btn-icon-prepend"></i> Delete
                  Data</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import "dropify";
// import { storePlayer } from "../../services/playerService";
import { deleteReward, getDetailReward } from "@/services/rewardService";
import Swal from 'sweetalert2';
import moment from 'moment';

export default {
  data: () => ({
    rule: {},
    assignees: [],
    loading: false,
  }),
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    async detailReward() {
      await getDetailReward(this.id).then((response) => {
        this.rule = response.data;
      });
    },
    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteReward(this.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The reward has been deleted.',
              confirmButtonColor: '#3085d6'
            }).then(() => {
              this.$router.push({ name: 'ListRewards' });
            });
          });
        }
      });
    },
    submitForm() {
      console.log(this.ruleStore);
    },
    formatDate(date) {
      return moment(date).format('DD MMM YYYY HH:mm:ss');
    }
  },
  async mounted() {
    await this.detailReward();
    this.assign = this.rule.assignees;
    // console.log();
    if (this.assign[0].user != null) {
      this.assign.forEach((assignee) => {
        this.assignees.push(assignee.user.name);
      });
    } else {
      this.assign.forEach((assignee) => {
        this.assignees.push(assignee.division.name);
      });
    }
  }
};
</script>

<style>
  .text-camelcase {
    text-transform: capitalize;
  }
</style>