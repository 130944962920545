<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Insert New Game player</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form class="forms-sample" method="post" @submit.prevent="submitForm">
            <div class="form-group row">
              <div class="col">
                <label>Player Name</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="playerStore.name"
                  id="exampleInputUsername1"
                />
              </div>
              <div class="col">
                <label for="dobInput">Date Of Birth</label>
                <input
                  id="dobInput"
                  type="date"
                  class="form-control"
                  v-model="playerStore.dob"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Email Address</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="playerStore.email"
                  id="exampleInputUsername1"
                />
              </div>
              <div class="col">
                <label>Phone Number</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="playerStore.phone_number"
                  id="exampleInputUsername1"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Update Password</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="playerStore.password"
                  id="exampleInputUsername1"
                />
              </div>
              <div class="col">
                <label>Squad</label>
                <select
                  class="form-control"
                  :style="{ width: '100%' }"
                  v-model="playerStore.division_id"
                >
                  <option
                    v-for="option in squadOptions"
                    :key="option.id"
                    :value="option.id"
                    :selected="option.id === player.division_id"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-3 col-sm-12">
                <label>User Photo</label>
                <div class="">
                  <img
                    width="100%"
                    v-if="player?.profile_picture"
                    :src="config.STORAGE_URL + player.profile_picture"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <label>Change Photo</label>
                <input
                  type="file"
                  class="dropify"
                  v-on:change="handleFileChange"
                />
              </div>
              <div class="col">
                <label for="positionInput">Position</label>
                <input
                  id="positionInput"
                  type="text"
                  class="form-control"
                  v-model="playerStore.position"
                />
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                @click="back"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div
                  class="spinner-border text-warning me-3"
                  :style="{ width: '14px', height: '14px' }"
                  role="status"
                  v-if="loading"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "dropify";
import { updatePlayer } from "@/services/playerService"; // Assume you have a service to update player
import { getDetailPlayer } from "@/services/playerService";
import { getSquad } from "../../services/playerService";
import config from "@/config/config";
import Swal from "sweetalert2";

export default {
  name: "EditPlayer",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    playerStore: {
      company_id: null,
      name: "",
      email: "",
      phone_number: "",
      password: "",
      division_id: null,
      profile_picture: null,
      role: "player",
      position: "",
    },
    squadOptions: [],
    player: "",
    config,
    loading: false,
  }),
  mounted() {
    $(".dropify").dropify({
      messages: {
        default: "Drag and drop a file here or click",
        replace: "Drag and drop or click to replace",
        remove: "Remove",
        error: "Ooops, something wrong happened.",
      },
      error: {
        fileSize: "The file size is too big ({{ value }} max).",
        minWidth: "The image width is too small ({{ value }}}px min).",
        maxWidth: "The image width is too big ({{ value }}}px max).",
        minHeight: "The image height is too small ({{ value }}}px min).",
        maxHeight: "The image height is too big ({{ value }}}px max).",
        imageFormat: "The image format is not allowed ({{ value }} only).",
      },
      tpl: {
        wrap: '<div class="dropify-wrapper"></div>',
        loader: '<div class="dropify-loader"></div>',
        message:
          '<div class="dropify-message"><span class="file-icon" /> <p>{{ default }}</p></div>',
        preview:
          '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"></div></div>',
        filename:
          '<p class="dropify-filename"><span class="file-icon" /> {{ name }}</p>',
        file: '<div class="dropify-file"></div>',
        clearButton:
          '<button type="button" class="dropify-clear">Remove</button>',
        errorLine: '<p class="dropify-error">{{ error }}</p>',
        errorsContainer:
          '<div class="dropify-errors-container"><ul></ul></div>',
      },
    });

    this.fetchDetailPlayer();
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await getSquad().then((response) => {
        if (response.data.length == 0) {
          this.squadOptions = []
        } else {
          response.data.forEach((item) => {
            this.squadOptions.push(item);
          });
        }
      });
    },
    fetchDetailPlayer() {
      getDetailPlayer(this.id).then((response) => {
        this.player = response.data;
        this.playerStore.company_id = response.data.company_id; // Assuming 'owner_id' is the company ID
        this.playerStore.division_id = response.data.division_id; // Assuming 'owner_id' is the company ID
        this.playerStore.name = response.data.name;
        this.playerStore.email = response.data.email;
        this.playerStore.phone_number = response.data.phone_number;
        this.playerStore.password = ""; // Passwords are typically not pre-filled
        this.playerStore.profile_picture = response.data.profile_picture;
        this.playerStore.dob = response.data.dob;
        this.playerStore.position = response.data.position;
      });
    },
    async submitForm() {
      try {
        this.loading = true;
        await updatePlayer(this.id, this.playerStore);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data player updated successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.go(-1);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.errors.email[0],
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        this.playerStore.profile_picture = event.target.files[0];
      } else {
        this.playerStore.profile_picture = this.player.profile_picture; // Ensure it's null if no files are selected
      }
    },
  },
};
</script>
