<template>
    <div class="row">
        <div class="page-header">
            <h3 class="page-title">Create New Reward</h3>
        </div>

        <div class="col-12 grid-margin">
            <div class="card">
                <div class="card-body">
                    <form class="forms-sample" method="post" @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="form-group row">
                            <div class="col">
                                <label for="nameInput">Name</label>
                                <input id="nameInput" type="text" class="form-control" v-model="ruleStore.name" />
                            </div>
                            <div class="col">
                                <label for="typeInput">Type</label>
                                <select class="form-control" id="typeInput" v-model="ruleStore.type">
                                    <option value="always_active">Always Active</option>
                                    <option value="onetime">One Time</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row gap-3">
                            <div class="col-md col-sm-12 grouping">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="emailInput">Reward</label>
                                        <select class="form-control" id="rewardInput" v-model="ruleStore.reward">
                                            <option value="gold">Gold</option>
                                            <option value="non_gold">Non Gold</option>
                                        </select>
                                    </div>
                                    <div class="col-12 mt-3">
                                        <div class="row" v-if="ruleStore.reward === 'gold'">
                                            <div class="col pe-0">
                                                <input type="number" class="form-control" v-model="ruleStore.gold" />
                                            </div>
                                            <div class="col-auto" :style="{ alignContent: 'center' }">
                                                <img :src="'https://gamiteam.ominddev.id/svg/gold.svg'"
                                                    :style="{ width: '100%', height: '30px' }" class="my-auto" alt="">
                                            </div>
                                        </div>
                                        <textarea type="text" class="form-control" v-model="ruleStore.non_gold" v-else>
                                        </textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md col-sm-12 grouping">
                                <div class="row">
                                    <div class="col-12">
                                        <label for="repeatInput">Repeat Type</label>
                                        <select class="form-control" id="repeatInput" v-model="ruleStore.repeat_type">
                                            <option value="no_repeat">No Repeat</option>
                                            <option value="daily">Daily</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                    </div>
                                    <div class="col-12">
                                        <ul class="custom-list" v-if="ruleStore.repeat_type === 'daily'">
                                            <li v-for="(day, index) in ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']"
                                                :key="index">
                                                <input type="checkbox" class="form-check-input" :id="day"
                                                    v-model="ruleStore.repeat_on_daily[day]" />
                                                <label class="form-check-label" :for="day">{{ day }}</label>
                                            </li>
                                        </ul>
                                        <ul class="custom-list" v-if="ruleStore.repeat_type === 'weekly'">
                                            <li v-for="(day, index) in ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']"
                                                :key="index">
                                                <input type="radio" class="form-check-input" :name="'weekly-' + day"
                                                    :value="index + 1" v-model="ruleStore.repeat_on_day" />
                                                <label class="form-check-label">{{ day }}</label>
                                            </li>
                                        </ul>
                                        <input v-if="ruleStore.repeat_type === 'monthly'" type="number"
                                            v-model="ruleStore.repeat_on_date" class="form-control mt-3" min="1"
                                            max="31" placeholder="1-31" />
                                    </div>
                                    <div class="col-12 mt-3" v-if="ruleStore.repeat_type !== 'no_repeat'">
                                        <label for="repeatInput">Ends On</label>
                                        <input type="date" class="form-control" v-model="ruleStore.ends_on" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row gap-3">
                            <div class="col grouping">
                                <label for="">
                                    <input type="checkbox" class="form-check-input mt-0 me-2"
                                        v-model="ruleStore.contest" />
                                    <label class="form-check-label">Contest</label>
                                </label>
                                <input type="text" class="form-control" v-model="ruleStore.date_period"
                                    v-if="ruleStore.contest" />
                            </div>
                            <div class="col grouping">
                                <label for="assignedToInput">Assigned To</label>
                                <select class="form-control" id="assignedToInput" v-model="ruleStore.assigned_to">
                                    <option value="all_player">All Player</option>
                                    <option value="specific_player">Specific Player</option>
                                    <option value="specific_squad">Specific Squad</option>
                                </select>
                                <!-- Update the select elements to bind to the selected values -->
                                <multiselect class="mt-3" v-if="ruleStore.assigned_to === 'specific_player'"
                                    v-model="ruleStore.assigned_user_id" :options="assignedUserId" :multiple="true"
                                    :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                                    placeholder="Select players" label="name" track-by="id">
                                </multiselect>
                                <multiselect class="mt-3" v-if="ruleStore.assigned_to === 'specific_squad'"
                                    v-model="ruleStore.assigned_division_id" :options="assignedDivisionId"
                                    :multiple="true" :close-on-select="false" :clear-on-select="false"
                                    :preserve-search="true" placeholder="Select squads" label="name" track-by="id">
                                </multiselect>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col">
                                <label for="descriptionInput">Description</label>
                                <textarea class="form-control" id="descriptionInput"
                                    v-model="ruleStore.description"></textarea>
                            </div>
                        </div>

                        <div class="form-group text-right">
                            <button type="button" @click="back"
                                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3" :disabled="loading">
                                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                                Back
                            </button>
                            <button type="submit" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                                :disabled="loading">
                                <div class="spinner-border text-warning me-3" :style="{ width: '14px', height: '14px' }"
                                    role="status" v-if="loading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import "dropify";
import { updateReward } from "../../services/rewardService";
import Swal from 'sweetalert2';
import { getUser, getDivision, getDetailReward } from '../../services/rewardService';
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data: () => ({
        ruleStore: {
            name: '',
            type: 'always_active',
            reward: '',
            gold: 0,
            non_gold: '',
            repeat_type: 'no_repeat',
            repeat_on_daily: {
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false
            },
            repeat_on_weekly: '',
            repeat_on_monthly: '',
            repeat_on_date: '',
            ends_on: '',
            description: '',
            contest: false,
            date_period: '',
            assigned_to: 'all_player',
            repeat_on_day: '',
            assigned_user_id: [],
            assigned_division_id: [],
            status: 'published',
        },
        loading: false,
        assignedUserId: [],
        assignedDivisionId: [],
    }),
    watch: {
        'ruleStore.repeat_type': function () {
            this.ruleStore.repeat_on_daily = {
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false
            };
            this.ruleStore.repeat_on_weekly = '';
            this.ruleStore.repeat_on_monthly = '';
            this.ruleStore.repeat_on_date = '';
            this.ruleStore.ends_on = '';
        },
        'ruleStore.assigned_to': function () {
            this.fetchAssignedData();
            if (this.ruleStore.assigned_to === 'all_player') {
                this.ruleStore.assigned_user_id = [];
                this.ruleStore.assigned_division_id = [];
            }
        }
    },
    computed: {
        repeatOnDaily() {
            return this.ruleStore.repeat_on_daily;
        },
        repeatOnWeekly() {
            return this.ruleStore.repeat_on_weekly;
        },
        repeatOnMonthly() {
            return this.ruleStore.repeat_on_monthly;
        }
    },
    methods: {
        async getDetailReward() {
            await getDetailReward(this.id).then(response => {
                // console.log(response.data);
                this.ruleStore.name = response.data.name;
                this.ruleStore.description = response.data.description;
                this.ruleStore.type = response.data.type;
                if (response.data.gold != null) {
                    this.ruleStore.reward = 'gold';
                    this.ruleStore.gold = response.data.gold;
                }

                if (response.data.non_gold != null) {
                    this.ruleStore.reward = 'non_gold';
                    this.ruleStore.non_gold = response.data.non_gold;
                }
                if (response.data.repeatable_rule != null) {
                    this.ruleStore.repeat_type = response.data.repeatable_rule.repeat_type;
                    Object.entries(response.data.repeatable_rule.repeat_on_daily).forEach(([day, value]) => {
                        this.ruleStore.repeat_on_daily[day] = value;
                        // console.log(this.ruleStore.repeat_on_daily);
                    });
                    console.log(response.data.repeatable_rule.ends_on);
                    this.ruleStore.ends_on = response.data.repeatable_rule.ends_on;
                }
                if (response.data.assignees.length > 0) {
                    response.data.assignees.forEach(assignee => {
                        if (assignee.user != null) {
                            this.ruleStore.assigned_to = 'specific_player';
                            this.fetchAssignedData();
                            this.ruleStore.assigned_user_id.push(assignee.user);
                        } else {
                            this.ruleStore.assigned_to = 'specific_squad';
                            this.fetchAssignedData();
                            this.ruleStore.assigned_division_id.push(assignee.division);
                        }
                    });
                }
            });
        },
        async fetchAssignedData() {
            if (this.ruleStore.assigned_to === 'specific_player') {
                await getUser().then(response => {
                    this.assignedUserId = response.data;
                });
                this.ruleStore.assigned_division_id = [];
            } else if (this.ruleStore.assigned_to === 'specific_squad') {
                await getDivision().then(response => {
                    this.assignedDivisionId = response.data;
                });
                this.ruleStore.assigned_user_id = [];
            }
        },
        back() {
            this.$router.go(-1);
        },
        submitForm() {
            this.loading = true;
            try {
                if (this.ruleStore.repeat_type === 'weekly' && !this.ruleStore.repeat_on_day) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Please select a day for weekly repeat',
                    });
                } else {
                    // Map to extract only the IDs
                    const payload = {
                        ...this.ruleStore,
                        assigned_user_id: this.ruleStore.assigned_user_id.map(user => user.id),
                        assigned_division_id: this.ruleStore.assigned_division_id.map(division => division.id),
                    };

                    updateReward(this.id, payload).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Reward has been stored',
                        });

                        this.$router.push('/rules/rewards');
                    }).catch(error => {
                        if (error.response.status === 422) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.response.data.message,
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'Failed to store reward',
                            });
                        }
                    });
                }
            } finally {
                this.loading = false;
            }
        }
    },
    mounted() {
        this.getDetailReward();
    }
};
</script>

<style>
ul.custom-list {
    list-style-type: none;
    padding: 0;
}

ul.custom-list li {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

ul.custom-list li input[type="checkbox"],
ul.custom-list li input[type="radio"] {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    transform: scale(1);
}

ul.custom-list li span {
    font-size: 1.2em;
}

.grouping {
    background-color: #282e3e;
    padding: 10px;
    border-radius: 10px;
}

.multiselect__input,
.multiselect__tags,
.multiselect__content-wrapper {
    background-color: #000000 !important;
    color: #fff !important;
    border: 1px solid #000 !important;
    border-radius: 2px;
}

.multiselect__option--highlight,
.multiselect__option--selected {
    background-color: #0090e7 !important;
    color: #fff !important;
}

.multiselect__tag {
    background-color: #0090e7 !important;
    color: #fff !important;
}
</style>
