<template>
    <!-- component:component/NavbarComponents.html -->
    <nav class="navbar p-0 fixed-top d-flex flex-row">
        <div class="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            <button
            class="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
            >
                <span class="mdi mdi-menu"></span>
            </button>

            <ul class="navbar-nav navbar-nav-right">
                <li class="nav-item dropdown">
                    <a
                    class="nav-link"
                    id="profileDropdown"
                    href="https://dashboard.gamiteam.app/home/home.html#"
                    data-bs-toggle="dropdown"
                    >
                        <div class="navbar-profile">
                            <img v-if="user.data.profile_picture"
                            class="img-xs rounded-circle"
                            :src="config.STORAGE_URL + user.data.profile_picture"
                            alt=""
                            />
                            <img v-else
                            class="img-xs rounded-circle"
                            src="../assets/images/faces/default.jpg"
                            alt=""
                            />
                            <p
                            class="mb-0 d-none d-sm-block navbar-profile-name"
                            style="color: #bcbf49"
                            >
                                {{ user.data.name }}
                            </p>
                            <i class="mdi mdi-menu-down d-none d-sm-block"></i>
                        </div>
                    </a>
                    <div
                    class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                    aria-labelledby="profileDropdown"
                    >
                        <h6 class="p-3 mb-0">{{ user.data.name }}</h6>
                        <div class="dropdown-divider"></div>
                        <router-link :to="{name: 'Profile'}" class="dropdown-item preview-item">
                            <div class="preview-thumbnail">
                                <div class="preview-icon bg-dark rounded-circle">
                                    <i class="mdi mdi-account text-success"></i>
                                </div>
                            </div>
                            <div class="preview-item-content">
                                <p class="preview-subject mb-1">Profile</p>
                            </div>
                        </router-link>
                        <!-- <div class="dropdown-divider"></div>
                        <a class="dropdown-item preview-item">
                            <div class="preview-thumbnail">
                                <div class="preview-icon bg-dark rounded-circle">
                                    <i class="mdi mdi-cog text-success"></i>
                                </div>
                            </div>
                            <div class="preview-item-content">
                                <p class="preview-subject mb-1">Settings</p>
                            </div>
                        </a> -->
                        <div class="dropdown-divider"></div>
                        <router-link :to="{name:'Logout'}"
                            class="dropdown-item preview-item"
                        >
                            <div class="preview-thumbnail">
                                <div class="preview-icon bg-dark rounded-circle">
                                    <i class="mdi mdi-logout text-danger"></i>
                                </div>
                            </div>
                            <div class="preview-item-content">
                                <p class="preview-subject mb-1">Log out</p>
                            </div>
                        </router-link>
                    </div>
                </li>
            </ul>
            <button
            class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            @click="toggleOffcanvas"
            >
                <span class="mdi mdi-format-line-spacing"></span>
            </button>
        </div>
    </nav>
</template>

<script>
import config from '@/config/config';

export default {
    data() {
        return {
            user: JSON.parse(this.$store.state.user),
            config
        };
    },
    methods: {
        toggleOffcanvas() {
            document.querySelector('.sidebar-offcanvas').classList.toggle('active');
        }
    }
};
</script>
