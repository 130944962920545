<template>
  <div class="row">
    <div class="page-header"><h3 class="page-title">Voucher Details</h3></div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            method="post"
            action="https://dashboard.gamiteam.app/voucher"
          >
            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Voucher Title</label>
                <div class="py-2">PROMO RAMADHAN</div>
              </div>
              <div class="col">
                <label for="exampleInputUsername1">Voucher Code</label>
                <div class="py-2">GMT1754G</div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Available</label>
                <div class="py-2">70</div>
              </div>
              <div class="col">
                <label for="exampleInputUsername1">Used</label>
                <div class="py-2">30</div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Discount</label>
                <div class="py-2">70%</div>
              </div>
              <div class="col">
                <label for="exampleInputUsername1">Minimum Transaction</label>
                <div class="py-2">Rp 1.000.000</div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Last Update</label>
                <div class="py-2">19 Nov 2022 12:22:11</div>
              </div>
              <div class="col">
                <label>Updated By</label>
                <div class="py-2">Admin 1</div>
              </div>
            </div>

            <div class="form-group">
              <button
                type="button"
                @click="back()"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <router-link :to="{name:'EditVoucher'}"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
              >
                <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Edit
                Data</router-link
              >
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="page-header">
      <h3 class="page-title">Voucher Submission</h3>
    </div>
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Quantity</th>
                  <th>Invoice No</th>
                  <th>Nominal</th>
                  <th>Type</th>
                  <th>Duration</th>
                  <th>Payment Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="ps-2">PT ABC Nusantara</span>
                  </td>
                  <td>10 Player</td>
                  <td>INV-2652</td>
                  <td>Rp 3.000.000</td>
                  <td><div class="badge badge-outline-info">Renewal</div></td>
                  <td><small>04 Dec 23 - 20 Jan 23 </small></td>
                  <td>
                    <div class="badge badge-outline-success">Paid</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">PT Technova Solusi</span>
                  </td>
                  <td>12 Player</td>
                  <td>INV-2652</td>
                  <td>Rp 3.000.000</td>
                  <td>New Register</td>
                  <td><small>04 Dec 23 - 20 Jan 23 </small></td>
                  <td>
                    <div class="badge badge-outline-warning">Unpaid</div>
                    &nbsp;
                    <button
                      type="button"
                      class="btn btn-outline-danger"
                      onclick="confirm('Apakah anda yakin ?')"
                    >
                      Mark as Paid
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">Mitra Belanja Terpercaya</span>
                  </td>
                  <td>14 Player</td>
                  <td>INV-2652</td>
                  <td>Rp 3.000.000</td>
                  <td>New Register</td>
                  <td><small>04 Dec 23 - 20 Jan 23 </small></td>
                  <td>
                    <div class="badge badge-outline-danger">Expired</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">Safro Nusantara</span>
                  </td>
                  <td>23 Player</td>
                  <td>INV-2652</td>
                  <td>Rp 3.000.000</td>
                  <td>New Register</td>
                  <td><small>04 Dec 23 - 20 Jan 23 </small></td>
                  <td>
                    <div class="badge badge-outline-success">Paid</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">Biznet Indihome</span>
                  </td>
                  <td>11 Player</td>
                  <td>INV-2652</td>
                  <td>Rp 3.000.000</td>
                  <td><div class="badge badge-outline-info">Renewal</div></td>
                  <td><small>04 Dec 23 - 20 Jan 23 </small></td>
                  <td>
                    <div class="badge badge-outline-success">Paid</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
