<template>
  <div class="row">
    <div class="page-header"><h3 class="page-title">Manage Voucher</h3></div>
    <div class="col-12 grid-margin">
      <button
        type="button"
        id="searchcolumn-btn"
        class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
        style=""
      >
        <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
      </button>
      &nbsp;
      <router-link
        :to="{name:'CreateVoucher'}"
        class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-plus btn-icon-prepend"></i> Create Voucher
      </router-link>
    </div>
    <div
      class="col-lg-6 col-md-12 grid-margin"
      style="display: none"
      id="searchcolumn"
    >
      <div class="card">
        <div class="card-body">
          <div class="btn-top-right">
            <button
              id="searchcolumn-close"
              class="btn btn-dark btn-xs"
              type="button"
            >
              <i class="mdi mdi-window-close btn-icon-prepend"></i>
            </button>
          </div>
          <form class="forms-sample">
            <div class="form-group row">
              <div class="col">
                <label>Title </label>
                <input class="form-control" type="text" placeholder="All" />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Code Voucher </label>
                <input class="form-control" type="text" placeholder="All" />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Sort By:</label>
                <select class="form-control">
                  <option>Input Date</option>
                  <option>Used</option>
                  <option>Available</option>
                </select>
              </div>
              <div class="col">
                <label>Ordering</label>
                <select class="form-control">
                  <option>Descending</option>
                  <option>Ascending</option>
                </select>
              </div>
            </div>
            <div class="form-group text-right">
              <button
                type="button"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
              >
                <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Title</th>
                  <th>Code Voucher</th>
                  <th>Discount</th>
                  <th>Min Trx</th>
                  <th>Used</th>
                  <th>Available</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span class="ps-2">1 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 11</router-link
                    >
                  </td>
                  <td>GMT162SS</td>
                  <td>38%</td>
                  <td>Rp 1.000.000</td>
                  <td>28</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">2 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 24</router-link
                    >
                  </td>
                  <td>GMT152SS</td>
                  <td>18%</td>
                  <td>Rp 1.000.000</td>
                  <td>15</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">3 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 12</router-link
                    >
                  </td>
                  <td>GMT184SS</td>
                  <td>76%</td>
                  <td>Rp 1.000.000</td>
                  <td>12</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">4 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 18</router-link
                    >
                  </td>
                  <td>GMT232SS</td>
                  <td>21%</td>
                  <td>Rp 1.000.000</td>
                  <td>17</td>
                  <td>27</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">5 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 16</router-link
                    >
                  </td>
                  <td>GMT273SS</td>
                  <td>66%</td>
                  <td>Rp 1.000.000</td>
                  <td>22</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">6 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 25</router-link
                    >
                  </td>
                  <td>GMT234SS</td>
                  <td>13%</td>
                  <td>Rp 1.000.000</td>
                  <td>20</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">7 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 23</router-link
                    >
                  </td>
                  <td>GMT248SS</td>
                  <td>73%</td>
                  <td>Rp 1.000.000</td>
                  <td>30</td>
                  <td>24</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">8 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 11</router-link
                    >
                  </td>
                  <td>GMT290SS</td>
                  <td>84%</td>
                  <td>Rp 1.000.000</td>
                  <td>26</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">9 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 27</router-link
                    >
                  </td>
                  <td>GMT228SS</td>
                  <td>71%</td>
                  <td>Rp 1.000.000</td>
                  <td>14</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">10 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 17</router-link
                    >
                  </td>
                  <td>GMT144SS</td>
                  <td>99%</td>
                  <td>Rp 1.000.000</td>
                  <td>27</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">11 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 20</router-link
                    >
                  </td>
                  <td>GMT173SS</td>
                  <td>62%</td>
                  <td>Rp 1.000.000</td>
                  <td>25</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">12 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 30</router-link
                    >
                  </td>
                  <td>GMT289SS</td>
                  <td>83%</td>
                  <td>Rp 1.000.000</td>
                  <td>20</td>
                  <td>26</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">13 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 11</router-link
                    >
                  </td>
                  <td>GMT110SS</td>
                  <td>15%</td>
                  <td>Rp 1.000.000</td>
                  <td>26</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">14 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 21</router-link
                    >
                  </td>
                  <td>GMT188SS</td>
                  <td>57%</td>
                  <td>Rp 1.000.000</td>
                  <td>26</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">15 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 25</router-link
                    >
                  </td>
                  <td>GMT109SS</td>
                  <td>23%</td>
                  <td>Rp 1.000.000</td>
                  <td>18</td>
                  <td>18</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">16 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 15</router-link
                    >
                  </td>
                  <td>GMT275SS</td>
                  <td>61%</td>
                  <td>Rp 1.000.000</td>
                  <td>20</td>
                  <td>28</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">17 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 27</router-link
                    >
                  </td>
                  <td>GMT168SS</td>
                  <td>93%</td>
                  <td>Rp 1.000.000</td>
                  <td>14</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">18 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 30</router-link
                    >
                  </td>
                  <td>GMT234SS</td>
                  <td>94%</td>
                  <td>Rp 1.000.000</td>
                  <td>21</td>
                  <td>17</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">19 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 25</router-link
                    >
                  </td>
                  <td>GMT237SS</td>
                  <td>12%</td>
                  <td>Rp 1.000.000</td>
                  <td>17</td>
                  <td>27</td>
                </tr>
                <tr>
                  <td>
                    <span class="ps-2">20 </span>
                  </td>

                  <td>
                    <router-link :to="{name:'DetailVoucher'}"
                      >Voucher Name 22</router-link
                    >
                  </td>
                  <td>GMT197SS</td>
                  <td>92%</td>
                  <td>Rp 1.000.000</td>
                  <td>26</td>
                  <td>26</td>
                </tr>
              </tbody>
            </table>
          </div>

          <nav class="mt-5">
            <ul class="pagination flat pagination-success separated">
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)"
                  ><i class="mdi mdi-chevron-left"></i
                ></a>
              </li>
              <li class="page-item active">
                <a class="page-link" href="javascript:void(0)">1</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">2</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">3</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)">4</a>
              </li>
              <li class="page-item">
                <a class="page-link" href="javascript:void(0)"
                  ><i class="mdi mdi-chevron-right"></i
                ></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "ListVoucher",
  mounted() {
    $("#searchcolumn-btn").click(() => {
      $("#searchcolumn").show(300);
      $("#searchcolumn-btn").hide(300);
    });

    $("#searchcolumn-close").click(() => {
      $("#searchcolumn").hide(300);
      $("#searchcolumn-btn").show(300);
    });
  },
};
</script>
