<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Add Quota</h3>
    </div>

    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="addQuota">
            <div class="form-group">
              <label for="quota">Quota Now</label>
              <div class="input-group">
                <input type="number" class="form-control bg-black" id="quota" v-model="quota" readonly>
                <div class="input-group-append">
                  <span class="input-group-text">person</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="additionalQuota">Additional Quota</label>
              <div class="input-group">
                <input type="number" class="form-control" id="additionalQuota" v-model="additionalQuota" required>
                <div class="input-group-append">
                  <span class="input-group-text">person</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="newQuota">New Quota</label>
              <div class="input-group">
                <input type="number" class="form-control bg-black" id="quota" v-model="quota" readonly>
                <div class="input-group-append">
                  <span class="input-group-text">person</span>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="newQuota">Payment Total</label>
              <div class="input-group">
                <div class="input-group-append">
                  <span class="input-group-text">Rp</span>
                </div>
                <input type="number" class="form-control bg-black" id="quota" v-model="quota" readonly>
              </div>
            </div>
            <div class="form-group text-right ">
              <button type="button" @click="back" class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading">
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i> Back
              </button>
              <button type="submit" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2" :disabled="loading">
                <div class="spinner-border text-warning me-3" :style="{ width: '14px', height: '14px' }" role="status"
                  v-if="loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all  btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "BillingView",
  data() {
    return {
      companyOptions: [],
      transaction: [],
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
    back() {
      this.$router.go(-1);
    },
  }
};
</script>