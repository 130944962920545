<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Manage Package</h3>
    </div>
    <div class="col-12 grid-margin">
      <router-link :to="{ name: 'CreatePackage' }" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2">
        <i class="mdi mdi-plus btn-icon-prepend"></i> Insert new Data
      </router-link>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="input-group">

<input v-model="search" placeholder="Search"
  style="float:right;background-color:transparent;border-color:aliceblue;color:aliceblue"
  class="col-md-2" />
<div class="input-group-append">

  <span class="input-group-text" style="border-color:aliceblue"><i class="mdi mdi-magnify"></i></span>
</div>
</div>
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Package Name</th>
                  <th>Price/Person/Month</th>
                  <th>Limit Users</th>
                  <th>Deskripsi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pkg, index) in filteredItems" :key="pkg.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    <router-link :to="{ name: 'DetailPackage', params: { id: pkg.id } }">
                      {{ pkg.package_name }}
                    </router-link>
                  </td>
                  <td>{{ formatterRupiah(pkg.price_per_person_per_month) }}</td>
                  <td>{{ pkg.limit_user  }}</td>
               
                  <td>{{ pkg.description }}</td>
                </tr>
                <!-- <tr v-if="paginatedPackages.length === 0">
                  <td colspan="4" class="text-center">No data available</td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div v-if="packages.length > 0">
            <PaginationComponents :totalItems="packages.length" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
              @page-changed="onPageChanged" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPackage } from '@/services/packageService';
import PaginationComponents from '@/components/PaginationComponents.vue';

export default {
  components: {
    PaginationComponents
  },
  data() {
    return {
      packages: [],
      currentPage: 1,
     itemsPerPage: 10,
      search: "",
      fetchDone: false,


    };
  },
  async mounted() {
    this.packages = await getPackage();
  },
  computed: {
    filteredItems() {
      return this.packages.filter(item => {
        return item.package_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
   
    paginatedPackages() {
      if (this.fetchDone) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.packages.slice(start, end);
      } else {
        return [];
      }
    }
  },
  methods: {
    
    formatterRupiah(value) {
      const intValue = parseInt(value, 10);
      return `Rp. ${intValue.toLocaleString('id-ID')}`;
    },
    onPageChanged(page) {
      this.currentPage = page;
    }
  }
}
</script>