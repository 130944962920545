<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Edit Package</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            method="post"
            @submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <div class="form-group row">
              <div class="col">
                <label for="nameInput">Package Name</label>
                <input
                  id="nameInput"
                  type="text"
                  class="form-control"
                  v-model="packageStore.package_name"
                />
              </div>
              <div class="col">
                <label for="price">Package Price/Person/Month</label>
                <input
                  id="price"
                  type="number"
                  class="form-control"
                  v-model="packageStore.price_per_person_per_month"
                />
              </div>
              <div class="col">
                <label for="price">Limit User</label>
                <input
                  id="price"
                  type="number"
                  class="form-control"
                  v-model="packageStore.limit_user"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="description">Description</label>
                <textarea
                  id="description"
                  type="text"
                  class="form-control"
                  v-model="packageStore.description"
                >
                </textarea>
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div class="spinner-border text-warning me-3" :style="{width: '14px', height: '14px'}" role="status" v-if="loading">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePackage } from "../../services/packageService";
import { getPackageDetail } from "../../services/packageService";
import Swal from 'sweetalert2';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    packageStore: {
      company_id: null,
      package_name: "",
      price_per_person_per_month: 0,
      description: "",
      limit_user:""
    },
    loading: false,
  }),
  mounted() {
    this.getPackageDetail();
  },
  methods: {
    async getPackageDetail() {
      await getPackageDetail(this.id).then((response) => {
        // this.packageStore = response.data;
        this.packageStore.price_per_person_per_month = parseInt(response.price_per_person_per_month);
        this.packageStore.description = response.description;
        this.packageStore.package_name = response.package_name;
        this.packageStore.limit_user = response.limit_user;
      });
    },
    async submitForm() {
      this.loading = true;
      try {
        await updatePackage(this.packageStore, this.id);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Package updated successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.go(-1);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>