<template>
  <nav class="mt-5">
    <ul class="pagination flat pagination-success separated">
      <li class="page-item" :class="{ disabled: currentPage === 1 }">
        <a class="page-link" href="javascript:void(0)" @click="prevPage">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li v-if="startPage > 1" class="page-item">
        <a class="page-link" href="javascript:void(0)" @click="changePage(1)">1</a>
      </li>
      <li v-if="startPage > 2" class="page-item disabled">
        <a class="page-link" href="javascript:void(0)">...</a>
      </li>
      <li
        v-for="page in pagesToShow"
        :key="page"
        class="page-item"
        :class="{ active: currentPage === page }"
      >
        <a class="page-link" href="javascript:void(0)" @click="changePage(page)">
          {{ page }}
        </a>
      </li>
      <li v-if="endPage < totalPages - 1" class="page-item disabled">
        <a class="page-link" href="javascript:void(0)">...</a>
      </li>
      <li v-if="endPage < totalPages" class="page-item">
        <a class="page-link" href="javascript:void(0)" @click="changePage(totalPages)">{{ totalPages }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage === totalPages }">
        <a class="page-link" href="javascript:void(0)" @click="nextPage">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    totalItems: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    },
    maxPagesToShow: {
      type: Number,
      default: 5
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    startPage() {
      const half = Math.floor(this.maxPagesToShow / 2);
      if (this.currentPage <= half) {
        return 1;
      } else if (this.currentPage + half >= this.totalPages) {
        return Math.max(1, this.totalPages - this.maxPagesToShow + 1);
      } else {
        return this.currentPage - half;
      }
    },
    endPage() {
      return Math.min(this.startPage + this.maxPagesToShow - 1, this.totalPages);
    },
    pagesToShow() {
      const pages = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    changePage(page) {
      if (page !== this.currentPage) {
        this.$emit('page-changed', page);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.$emit('page-changed', this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.$emit('page-changed', this.currentPage + 1);
      }
    }
  }
};
</script>

<style scoped>
.page-item.disabled .page-link {
  pointer-events: none;
  color: #cccccc;
  background-color: rgba(128, 128, 128, 0.1);
}
</style>