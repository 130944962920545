import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL; 

export const getRedeems = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}redeem`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const storeRedeem = async (redeem) => {
  const token = store.state.authToken;
  const response = await axios.post(`${API_URL}redeem`, redeem, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } });
  return response.data;
};

export const getDetailRedeem = async (id) => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}redeem/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const deleteRedeem = async (id) => {
  const token = store.state.authToken;
  const response = await axios.delete(`${API_URL}redeem/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const updateRedeem = async (id, data) => {
  const token = store.state.authToken;
  const response = await axios.put(`${API_URL}redeem/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};


