<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Manage Game Payment</h3>
    </div>
    <div class="col-12 grid-margin">
      <button
        type="button"
        id="searchcolumn-btn"
        class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
      </button>
      &nbsp;
      <router-link
        :to="{ name: 'CreatePayment' }"
        class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-plus btn-icon-prepend"></i> Insert new Payment
      </router-link>
    </div>
    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(payment, index) in paginatedPayment" :key="payment.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    <router-link :to="{ name: 'DetailPayment', params: { id: payment.id }}">
                      {{ payment.name }}
                    </router-link>
                  </td>
                  <td>{{ formatRupiah(payment.price) }}</td>
                  <td>
                    <div :class="['badge', payment.status === 'Active' ? 'badge-outline-success' : 'badge-outline-danger']">
                      {{ payment.status || '-' }}
                    </div>
                  </td>
                </tr>
                <tr v-if="paginatedPayment.length === 0">
                  <td colspan="7" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="payment.length > 0">
            <PaginationComponents
              :totalItems="payment.length"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @page-changed="onPageChanged"
            />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { getPayment } from '../../services/paymentService';
import moment from 'moment';
import PaginationComponents from '../../components/PaginationComponents.vue';

export default {
  components: {
    PaginationComponents
  },
  data() {
    return {
      payment: [],
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    paginatedPayment() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.payment.slice(start, end);
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },
    fetchPayment() {
      getPayment().then(response => {
        this.payment = response;
      }).catch(error => {
        console.error('Error fetching payments:', error);
      })
    },
    onPageChanged(page) {
      this.currentPage = page;
    },
      formatRupiah(number) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(number);
      }
  },
  mounted() {
    this.fetchPayment();
    $("#searchcolumn-btn").click(() => {
      $("#searchcolumn").show(300);
      $("#searchcolumn-btn").hide(300);
    });

    $("#searchcolumn-close").click(() => {
      $("#searchcolumn").hide(300);
      $("#searchcolumn-btn").show(300);
    });
  },
};
</script>