<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Squad List</h3>
    </div>
    <div class="col-12 grid-margin">
      <router-link
        :to="{ name: 'CreateSquad' }"
        class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-plus btn-icon-prepend"></i> Insert new Data
      </router-link>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="input-group">
              <input
                @keyup="getBilingAdminsearch"
                v-model.trim="search"
                placeholder="Search"
                style="
                  float: right;
                  background-color: transparent;
                  border-color: aliceblue;
                  color: aliceblue;
                "
                class="col-md-2"
              />
              <div class="input-group-append">
                <span class="input-group-text" style="border-color: aliceblue"
                  ><i class="mdi mdi-magnify"></i
                ></span>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Squad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedRedeem" :key="data.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    <router-link
                      :to="{ name: 'DetailSquad', params: { id: data.id } }"
                    >
                      {{ data.name }}
                    </router-link>
                  </td>
                </tr>
                <tr v-if="paginatedRedeem.length === 0">
                  <td colspan="7" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="squads.length > 0">
            <PaginationComponents
              :totalItems="squads.length"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @page-changed="onPageChanged"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSquads } from "../../services/squadService";
import PaginationComponents from "../../components/PaginationComponents.vue";
import axios from "axios";
import config from "../../config/config";
import store from "../../store";
export default {
  components: {
    PaginationComponents,
  },
  data() {
    return {
      search: "",
      squads: [],
      currentPage: 1,
     itemsPerPage: 10,
      fetchDone: false,
    };
  },
  computed: {
    filteredItems() {
      return this.squads.filter((item) => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    paginatedRedeem() {
      if (this.fetchDone) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.squads.slice(start, end);
      } else {
        return [];
      }
    },
  },
  async mounted() {
    await this.getSquads().then(() => {
      this.fetchDone = true;
    });
  },
  methods: {
    getBilingAdminsearch() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}division`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (this.search) {
            this.squads = resp.data.data.filter((squads) =>
              squads.name.toLowerCase().includes(this.search.toLowerCase())
            );
          } else {
            _.squads = resp.data.data;
          }
        });
    },
    async getSquads() {
      await getSquads().then((response) => {
        this.squads = response.data;
      });
    },

    onPageChanged(page) {
      this.currentPage = page;
    },
  },
};
</script>
