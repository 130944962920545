<template>
  <div class="row">
    <div class="page-header"><h3 class="page-title">Create Voucher</h3></div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            method="post"
            action="https://dashboard.gamiteam.app/voucher"
          >
            <div class="form-group row">
              <div class="col-6">
                <label>Voucher Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                />
              </div>
              <div class="col-6">
                <label>Voucher Code</label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-6">
                <label>Minimum Transaction</label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputUsername1"
                />
              </div>

              <div class="col-6">
                <label>Quantity</label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputUsername1"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-6">
                <label>Discount Type</label>
                <select class="form-control">
                  <option>Percentage</option>
                  <option>Value</option>
                </select>
              </div>

              <div class="col-6">
                <label>Discount Amount</label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputUsername1"
                />
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back()"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
              >
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitForm() {
      // implement form submission logic here
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      this.companyLogo = event.target.files[0];
    },
  },
};
</script>
