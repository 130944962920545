<template>
  <div class="row">
    <div class="page-header"><h3 class="page-title">Edit User</h3></div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            @submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <div class="form-group row">
              <div class="col">
                <label for="userName">User Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="userName"
                  v-model="userStore.name"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="emailAddress">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  id="emailAddress"
                  v-model="userStore.email"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="phoneNumber">Phone Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="phoneNumber"
                  v-model="userStore.phone_number"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-3 col-sm-12">
                <label for="userPhoto">Change Profile Photo</label>
                <input
                  type="file"
                  class="dropify"
                  id="userPhoto"
                  @change="handleFileChange"
                />
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div
                  class="spinner-border text-warning me-3"
                  :style="{ width: '14px', height: '14px' }"
                  role="status"
                  v-if="loading"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Update Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "dropify";
import { updateProfile } from "@/services/authService";
import { getProfile } from '@/services/authService';
import config from "@/config/config";
import Swal from "sweetalert2";
import store from '@/store';

export default {
  name: "EditUser",
  data: () => ({
    userStore: {
      name: "",
      email: "",
      phone_number: "",
      profile_picture: null,
    },
    id: JSON.parse(store.state.user).data.id,
    config,
    loading: false,
  }),
  mounted() {
    $(".dropify").dropify({
      messages: {
        default: "Drag and drop a file here or click",
        replace: "Drag and drop or click to replace",
        remove: "Remove",
        error: "Ooops, something wrong happened.",
      },
    });

    this.fetchDetailUser();
  },
  methods: {
    fetchDetailUser() {
        getProfile(this.id).then((response) => {
          this.userStore.name = response.data.name;
          this.userStore.email = response.data.email;
          this.userStore.phone_number = response.data.phone_number; // Corrected the property name for phone number
          this.userStore.password = ""; // Passwords are typically not pre-filled
          this.userStore.profile_picture = response.data.profile_picture;
      });
    },
    async submitForm() {
      this.loading = true;
      try {
        await updateProfile(this.id, this.userStore);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "User data updated successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.go(-1);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.errors[0],
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        this.userStore.profile_picture = event.target.files[0];
      } else {
        this.userStore.profile_picture = null; // Ensure it's null if no files are selected
      }
    },
  },
};
</script>

