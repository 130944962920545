import axios from "axios";
import config from "../config/config";
import store from "../store"

const API_URL = config.API_URL; 

export const storePackage = async (data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}package`, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const getPackage = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}package`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data.data;
}

export const getPackageDetail = async (id) => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}package/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data.data;
}

export const deletePackage = async (id) => {
    const token = store.state.authToken;
    const response = await axios.delete(`${API_URL}package/${id}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}

export const updatePackage = async (data, id) => {
    const token = store.state.authToken;
    const response = await axios.put(`${API_URL}package/${id}`, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
}