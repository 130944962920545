import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL; 

export const getRewardApproval = async () => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-rule`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const getDetailRewardApproval = async (id) => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-rule/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const updateRewardApproval = async (id, status, rejection_notes) => {
    const token = store.state.authToken;
    const res = await axios.put(`${API_URL}user-rule/${id}`, { status, rejection_notes }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const getPunishmentApproval = async () => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-punishment`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const getDetailPunishmentApproval = async (id) => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-punishment/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const updatePunishmentApproval = async (id, status, rejection_notes) => {
    const token = store.state.authToken;
    const res = await axios.put(`${API_URL}user-punishment/${id}`, { status, rejection_notes }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const getConsequenceApproval = async () => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-consequence`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const getDetailConsequenceApproval = async (id) => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-consequence/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const updateConsequenceApproval = async (id, status, rejection_notes) => {
    const token = store.state.authToken;
    const res = await axios.put(`${API_URL}user-consequence/${id}`, { status, rejection_notes }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const getCompensationApproval = async () => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-compensation`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const getDetailCompensationApproval = async (id) => {
    const token = store.state.authToken;
    const res = await axios.get(`${API_URL}user-compensation/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const updateCompensationApproval = async (id, status, rejection_notes) => {
    const token = store.state.authToken;
    const res = await axios.put(`${API_URL}user-compensation/${id}`, { status, rejection_notes }, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};