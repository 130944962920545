<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Discount</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            method="post"
            @submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <div class="form-group row">
              <div class="col">
                <label for="nameInput">Title</label>
                <input
                  id="nameInput"
                  type="text"
                  class="form-control"
                  v-model="model.title"
                />
              </div>
              <div class="col">
                <label for="dobInput">Expired at</label>
                <input
                  id="dobInput"
                  type="date"
                  class="form-control"
                  v-model="model.expired_at"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="emailInput">Description</label>
                <input
                  id="emailInput"
                  type="text"
                  class="form-control"
                  v-model="model.desc"
                />
              </div>
              <div class="col">
                <label for="phone_numberInput">Type</label>
                <select name="" v-model="model.type" class="form-control" id="">
                  <option value="">Select Type</option>
                  <option value="percent">%</option>
                  <option value="flat">Rp</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="passwordInput">Value</label>
                <input
                  id="passwordInput"
                  type="number"
                  class="form-control"
                  v-model="model.value"
                />
              </div>
              <div class="col">
                <label for="passwordInput">Limit User</label>
                <input
                  id="passwordInput"
                  type="number"
                  class="form-control"
                  v-model="model.limit_user"
                />
              </div>
              <div class="col">
                <label for="">Code</label>
                <input
                  id=""
                  type="text"
                  class="form-control"
                  v-model="model.code"
                />
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
             
               
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import { getDetailDiscount } from "../../services/discountServices";
import { updateDiscount } from "../../services/discountServices";

import config from "@/config/config";
import Swal from "sweetalert2";

export default {
  name: "EditPlayer",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    model: {
      title: "",
      desc: "",
      type: "",
      value: 0,
      code: "",
      expired_at: "",
      limit_user:0
    },
    discount: "",
   
    config,
    loading: false,
  }),
  mounted() {
  
    this.fetchDetailDiscont();
    // this.fetchData();
  },
  methods: {
    // async fetchData() {
    //   await getDetailDiscount().then((response) => {
    //     if (response.data.length == 0) {
    //       this.squadOptions = []
    //     } else {
    //       response.data.forEach((item) => {
    //         this.squadOptions.push(item);
    //       });
    //     }
    //   });
    // },
    fetchDetailDiscont() {
      getDetailDiscount(this.id).then((response) => {
        this.discount = response.data.data;
        this.model.title = response.data.title; // Assuming 'owner_id' is the company ID
        this.model.desc = response.data.desc; // Assuming 'owner_id' is the company ID
        this.model.type = response.data.type;
        this.model.value = response.data.value;
        this.model.code = response.data.code;
        this.model.limit_user = response.data.limit_user;
        this.model.expired_at = response.data.expired_at;
       
      });
    },
    async submitForm() {
      try {
        this.loading = true;
        await updateDiscount(this.id, this.model);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data Discont updated successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.go(-1);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.errors[0],
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
  
  },
};
</script>
