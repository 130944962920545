<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Consequence Approval List</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="input-group">
              <!-- <select name="" class="col-md-2" v-model="search" id=""
                style="float:right;background-color:transparent;border-color:aliceblue;color:aliceblue">
                <option value="" style="color:black">Semua Status</option>
                <option value="Pending" style="color:black">Pending</option>
                <option value="Rejected" style="color:black">Rejected</option>
                <option value="Approved" style="color:black">Approved</option>

              </select> -->
              <input
                @keyup="getBilingAdminsearch"
                v-model.trim="search"
                placeholder="Search"
                style="
                  float: right;
                  background-color: transparent;
                  border-color: aliceblue;
                  color: aliceblue;
                "
                class="col-md-2"
              />
              <div class="input-group-append">

                <span class="input-group-text" style="border-color:aliceblue"><i class="mdi mdi-magnify"></i></span>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Player</th>
                  <th>Consequence</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedconsequenceApproval" :key="data.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    <router-link :to="{ name: 'EditConsequenceApproval', params: { id: data.id } }">
                      {{ data.user.name }}
                    </router-link>
                  </td>
                  <td>{{ data.consequence.name }}</td>
                  <td>
                    <span class="badge badge-warning" v-if="data.status === 'pending'">Pending</span>
                    <span class="badge badge-success" v-if="data.status === 'approved'">Approved</span>
                    <span class="badge badge-danger" v-if="data.status === 'rejected'">Rejected</span>
                  </td>
                </tr>
                <tr v-if="consequenceApproval.length === 0">
                  <td colspan="7" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="consequenceApproval.length > 0">
            <PaginationComponents
              :totalItems="consequenceApproval.length"
              :itemsPerPage="itemsPerPage"
              :currentPage="currentPage"
              @page-changed="onPageChanged"
              
            />
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getConsequenceApproval } from "../../services/approvalService";
import moment from 'moment';
import PaginationComponents from '../../components/PaginationComponents.vue';
import axios from "axios";
import config from "../../config/config";
import store from "../../store";
export default {
  components: {
    PaginationComponents
  },
  data() {
    return {
      consequenceApproval: [],
      currentPage: 1,
      search: "",
     itemsPerPage: 10,
      mountedDone: false,
    };
  },
  computed: {
    filteredItems() {
      return this.consequenceApproval.filter(item => {
        return item.status.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
    paginatedconsequenceApproval() {
      if (this.mountedDone) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.consequenceApproval.length > 0 ? this.consequenceApproval.slice(start, end) : [];
      } else {
        return [];
      }
    },
  },
  methods: {
    getBilingAdminsearch() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}user-consequence`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (this.search) {
            this.consequenceApproval = resp.data.data.filter(
              (consequenceApproval) =>
              consequenceApproval.user.name
                  .toLowerCase()
                  .includes(this.search.toLowerCase())
            );
          } else {
            _.consequenceApproval = resp.data.data;
          }
        });
    },
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },
    async fetchData() {
      await getConsequenceApproval().then((response) => {
        this.consequenceApproval = response.data;
      }).catch(error => {
        console.error('Error fetching consequenceApprovals:', error);
      })
    },
    onPageChanged(page) {
      this.currentPage = page;
    }
  },
  async mounted() {
    await this.fetchData().then(() => {
      this.mountedDone = true;
    });
    this.getBilingAdminsearch()
  },
};
</script>
