<template>
    <div class="row">
      <div class="page-header">
        <h3 class="page-title">Game User Details</h3>
      </div>
  
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <form
              class="forms-sample"
              method="post"
              action="https://dashboard.gamiteam.app/user"
            >
              <div class="form-group row">
                <div class="col">
                  <label for="exampleInputUsername1">Name</label>
                  <div class="py-2">{{ user.name }}</div>
                  <div class="py-2">
                    <img width="100" :src="user.profile_picture ? config.STORAGE_URL + user.profile_picture : require('../assets/images/faces/default.jpg')" />
                  </div>
                </div>
                <div class="col">
                  <label>Role</label>
                  <div class="py-2">{{ user.role.charAt(0).toUpperCase() + user.role.slice(1) }}</div>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col">
                  <label for="exampleInputUsername1">Email</label>
                  <div class="py-2">{{ user.email }}</div>
                </div>
                <div class="col">
                  <label>Phone Number</label>
                  <div class="py-2">{{ user.phone_number }}</div>
                </div>
              </div>
  
              <div class="form-group">
                <button
                  type="button"
                  @click="back"
                  class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 me-3"
                >
                  <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                  Back
                </button>
                <router-link
                  :to="{name:'ProfileEdit'}"
                  class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2 me-3"
                >
                  <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Edit
                  Data</router-link
                >
                <router-link
                  :to="{name:'ProfileEditPassword'}"
                  class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2 me-3"
                >
                  <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Reset
                  Password</router-link
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  import { getProfile } from '@/services/authService';
  import config from '@/config/config';
  import store from '@/store';
  
  export default {
    data() {
      return {
        user: '',
        id: JSON.parse(store.state.user).data.id,
        config
      }
    },
    mounted() {
      this.fetchDetailUser();
    },
    methods: {
      fetchDetailUser() {
        getProfile(this.id).then(response => {
          this.user = response.data;
        });
      },
      formatDate(date) {
        return moment(date).format('DD MMMM YYYY');
      },
      back() {
        this.$router.go(-1);
      },
    },
  };
  </script>
  
  