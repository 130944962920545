import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL;

export const storeDiscont = async (data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}admin/discount/store`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const getDiscount = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}admin/discount`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    
    return response.data;
}

export const getDetailDiscount = async (id) => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}admin/discount/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const updateDiscount = async (id, data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}admin/discount/update/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const deleteDiscount = async (id) => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}admin/discount/destroy/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

