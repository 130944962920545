<template>
    <div class="row">
        <div class="page-header">
            <h3 class="page-title">Package Details</h3>
        </div>

        <div class="col-12 grid-margin">
            <div class="card">
                <div class="card-body">
                    <form class="forms-sample">
                        <div class="form-group row">
                            <div class="col">
                                <label for="exampleInputUsername1">Package Name</label>
                                <div class="py-2">{{ packageDetail.package_name }}</div>
                            </div>
                            <div class="col">
                                <label>Price/Unit</label>
                                <div class="py-2">{{ formatRupiah(packageDetail.price_per_person_per_month) }}</div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col">
                                <label for="exampleInputUsername1">Description</label>
                                <div class="py-2">{{ packageDetail.description }}</div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col">
                                <label for="exampleInputUsername1">Limit Users</label>
                                <div class="py-2">{{ packageDetail.limit_user }}</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="button" @click="back"
                                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 me-3">
                                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                                Back
                            </button>
                            <router-link :to="{ name: 'EditPackage', params: { id: packageDetail.id } }"
                                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2 me-3">
                                <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Edit
                                Data</router-link>
                            <a @click="confirmDelete" class="btn btn-outline-danger btn-icon-text mt-2 px-5 py-2">
                                <i class="mdi mdi-delete-outline btn-icon-prepend"></i> Delete
                                Data</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '@/config/config';
import Swal from 'sweetalert2';
import { deletePackage, getPackageDetail } from '@/services/packageService';

export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            packageDetail: {},
            config
        }
    },
    mounted() {
        this.fetchPackageDetail();
    },
    methods: {
        fetchPackageDetail() {
            getPackageDetail(this.id).then(response => {
                this.packageDetail = response;
            });
        },
        back() {
            this.$router.go(-1);
        },
        formatRupiah(value) {
            return `Rp. ${parseInt(value).toLocaleString('id-ID')}`;
        },
        confirmDelete() {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    deletePackage(this.id).then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Deleted!',
                            text: 'The package has been deleted.',
                            confirmButtonColor: '#3085d6'
                        }).then(() => {
                            this.$router.push({ name: 'ListPackage' });
                        });
                    });
                }
            });
        },
    },
};
</script>