<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Quota</h3>
    </div>
    <div class="col-12 grid-margin">
      <router-link :to="{ name: 'QuotaAdd' }" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2">
        <i class="mdi mdi-plus btn-icon-prepend"></i> Add Quota
      </router-link>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <td>Created Date</td>
                  <td>Total Amount</td>
                  <td>Deadline</td>
                  <td>Number of Quota</td>
                  <td>Status</td>
                  <td>Invoice</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>16/06/2024</td>
                  <td>Rp. 3.000.000</td>
                  <td>11/12/2024</td>
                  <td>10</td>
                  <td>
                    <div class="badge badge-outline-warning">
                      Unpaid
                    </div>
                  </td>
                  <td>
                    <button type="button" class="btn btn-primary btn-icon-text" @click="openModal">
                      Pay
                    </button>

                    <!-- Manual Modal -->
                    <div v-if="isModalOpen" class="custom-modal">
                      <div class="custom-modal-content">
                        <div class="custom-modal-header">
                          <p>
                            BCA Virtual Account PT. Lightrees
                          </p>
                          <h4>
                            No. 1234567890
                          </h4>
                          <button type="button" class="close" @click="closeModal">&times;</button>
                        </div>
                        <div class="custom-modal-body">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>No</th>
                                  <th>Invoice Number</th>
                                  <th>Description</th>
                                  <th>Total (Rp)</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1</td>
                                  <td>INV/2024/01/001</td>
                                  <td>Payment for Quota</td>
                                  <td>Rp. 3.000.000</td>
                                </tr>
                                <tr>
                                  <td class="text-end" colspan="3">Subtotal</td>
                                  <td>Rp. 3.000.000</td>
                                </tr>
                                <tr>
                                  <td class="text-end" colspan="3">PPN 11%</td>
                                  <td>Rp. 330.000</td>
                                </tr>
                                <tr>
                                  <td class="text-end" colspan="3">Deposit</td>
                                  <td>Rp. 100.000</td>
                                </tr>
                                <tr>
                                  <td class="text-end" colspan="3">Total</td>
                                  <td>Rp. 3.430.000</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <p class="text-danger mt-3">Warning:</p>
                          <p>1. Minimal transaksi Rp 10.000 setiap bulannya sama dengan BCA Virtual Account.</p>
                          <p>2. Nomor Virtual Account BCA akan menunjukkan total tagihan yang aktif.</p>
                          <p>3. Pembayaran dapat dilakukan melalui ATM BCA, KlikBCA Individu, KlikBCA Bisnis, BCA Mobile, dan Teller.</p>
                          <p>4. Pembayaran juga dapat dilakukan melalui Switching.</p>
                        </div>
                        <div class="custom-modal-footer">
                          <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                          <button type="button" class="btn btn-primary" @click="openConfirmationModal">Transfer Manual</button>
                        </div>
                      </div>
                    </div>

                    <!-- Confirmation Modal -->
                    <div v-if="isConfirmationModalOpen" class="custom-modal">
                      <div class="custom-modal-content custom-modal-centered">
                        <div class="custom-modal-header">
                          <h4>Confirm Transfer</h4>
                          <button type="button" class="close" @click="closeConfirmationModal">&times;</button>
                        </div>
                        <div class="custom-modal-body">
                          <p>Are you sure you want to proceed with the manual transfer?</p>
                        </div>
                        <div class="custom-modal-footer">
                          <button type="button" class="btn btn-secondary" @click="closeConfirmationModal">Cancel</button>
                          <button type="button" class="btn btn-primary" @click="confirmTransfer">Confirm</button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>16/06/2024</td>
                  <td>Rp. 3.000.000</td>
                  <td>11/12/2024</td>
                  <td>10</td>
                  <td>
                    <div class="badge badge-outline-success">
                      Paid
                    </div>
                  </td>
                  <td>
                    <button class="btn btn-outline-info btn-icon-text">
                      View Attachment
                    </button>
                  </td>
                </tr>
                <!-- <tr v-for="(data) in paginatedTransaction" :key="data.id">
                    <td>
                        {{ data.owner.company_name || '-' }}
                    </td>
                    <td>{{ data.number_of_players || '-' }} Player</td>
                    <td>{{ data.invoice_number || '-' }}</td>
                    <td>-</td>
                    <td>{{ data.type || '-' }}</td>
                    <td>{{ formatDate(data.start_month) + ' - ' + formatDate(data.end_month) || '-' }}</td>
                    <td>
                      <div :class="['badge', data.payment_status === 'Paid' ? 'badge-outline-success' : 'badge-outline-danger']">
                        {{ data.payment_status || '-' }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="paginatedTransaction.length === 0">
                    <td colspan="7" class="text-center">No data available</td>
                  </tr> -->
              </tbody>
            </table>
          </div>

          <!-- <div v-if="transaction.length > 0">
              <PaginationComponents
                :totalItems="transaction.length"
                :itemsPerPage="itemsPerPage"
                :currentPage="currentPage"
                @page-changed="onPageChanged"
              />
            </div> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "BillingView",
  data() {
    return {
      companyOptions: [],
      transaction: [],
      currentPage: 1,
     itemsPerPage: 10,
      isModalOpen: false,
      isConfirmationModalOpen: false
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY');
    },
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    openConfirmationModal() {
      this.closeModal();
      this.isConfirmationModalOpen = true;
    },
    closeConfirmationModal() {
      this.isConfirmationModalOpen = false;
    },
    confirmTransfer() {
      // Add your transfer logic here
      this.$router.push({ name: 'DetailQuota', params: { id: 1 } });
      this.closeConfirmationModal();
      this.closeModal();
    }
  }
};
</script>

<style>
.custom-modal {
  display: block;
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.custom-modal-content {
  position: relative;
  margin: 1.75rem auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  background-color: black;
  width: 90%;
  max-width: 800px;
  padding: 1rem;
  overflow: hidden;
  word-wrap: break-word; /* Added to break text to the next line */
}

@media (max-width: 768px) {
  .custom-modal-content {
    width: 95%;
    padding: 0.5rem;
  }
}

.custom-modal-centered {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.custom-modal-header,
.custom-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dee2e62b;
}

.custom-modal-header {
  border-bottom: 1px solid #dee2e62b;
}

.custom-modal-footer {
  border-top: 1px solid #dee2e62b;
}

.custom-modal-body {
  position: relative;
  padding: 1rem;
  overflow-y: auto;
  max-height: 70vh;
}

.close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>