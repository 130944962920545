<template>
  <div class="row">
    <div class="page-header"><h3 class="page-title">Edit Game Owner</h3></div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            @submit.prevent="submitForm"
            enctype="multipart/form-data"
          >
            <div class="form-group row">
              <div class="col">
                <label for="companyName">Company Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="companyName"
                  v-model="ownerStore.companyName"
                />
              </div>
              <div class="col">
                <label for="ownerName">Owner Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="ownerName"
                  v-model="ownerStore.ownerName"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="emailAddress">Email Address</label>
                <input
                  type="email"
                  class="form-control"
                  id="emailAddress"
                  v-model="ownerStore.emailAddress"
                />
              </div>
              <div class="col">
                <label for="phoneNumber">Phone Number</label>
                <input
                  type="tel"
                  class="form-control"
                  id="phoneNumber"
                  v-model="ownerStore.phoneNumber"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="updatePassword">Update Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="updatePassword"
                  v-model="ownerStore.password"
                />
              </div>
              <div class="col">
                <!-- Empty column for alignment -->
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3 col-sm-12">
                <label>Company Logo</label>
                <div class="">
                  <img
                    width="100%"
                    v-if="owner.company && owner.company.logo"
                    :src="config.STORAGE_URL + owner.company.logo"
                  />
                </div>
              </div>
              <div class="col-md-3 col-sm-12">
                <label for="companyLogo">Change Company Logo</label>
                <input
                  type="file"
                  class="dropify"
                  id="companyLogo"
                  @change="handleFileChange"
                />
              </div>
            </div>

            <div class="form-group text-right">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3"
                :disabled="loading"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <button
                type="submit"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                :disabled="loading"
              >
                <div
                  class="spinner-border text-warning me-3"
                  :style="{ width: '14px', height: '14px' }"
                  role="status"
                  v-if="loading"
                >
                  <span class="visually-hidden">Loading...</span>
                </div>
                <i class="mdi mdi-check-all btn-icon-prepend"></i> Update Data
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "dropify";
import { updateOwner } from "@/services/ownerService"; // Assume you have a service to update owner
import { getDetailOwner } from "@/services/ownerService";
import config from "@/config/config";
import Swal from "sweetalert2";

export default {
  name: "EditOwner",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    ownerStore: {
      companyName: "",
      ownerName: "",
      emailAddress: "",
      phoneNumber: "",
      password: "",
      companyLogo: null,
    },
    owner: "",
    config,
    loading: false,
  }),
  mounted() {
    $(".dropify").dropify({
      messages: {
        default: "Drag and drop a file here or click",
        replace: "Drag and drop or click to replace",
        remove: "Remove",
        error: "Ooops, something wrong happened.",
      },
      error: {
        fileSize: "The file size is too big ({{ value }} max).",
        minWidth: "The image width is too small ({{ value }}}px min).",
        maxWidth: "The image width is too big ({{ value }}}px max).",
        minHeight: "The image height is too small ({{ value }}}px min).",
        maxHeight: "The image height is too big ({{ value }}}px max).",
        imageFormat: "The image format is not allowed ({{ value }} only).",
      },
      tpl: {
        wrap: '<div class="dropify-wrapper"></div>',
        loader: '<div class="dropify-loader"></div>',
        message:
          '<div class="dropify-message"><span class="file-icon" /> <p>{{ default }}</p></div>',
        preview:
          '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"></div></div>',
        filename:
          '<p class="dropify-filename"><span class="file-icon" /> {{ name }}</p>',
        file: '<div class="dropify-file"></div>',
        clearButton:
          '<button type="button" class="dropify-clear">Remove</button>',
        errorLine: '<p class="dropify-error">{{ error }}</p>',
        errorsContainer:
          '<div class="dropify-errors-container"><ul></ul></div>',
      },
    });

    this.fetchDetailOwner();
  },
  methods: {
    fetchDetailOwner() {
      getDetailOwner(this.id).then((response) => {
        this.owner = response;

        this.ownerStore.companyName = response.company.name;
        this.ownerStore.ownerName = response.name;
        this.ownerStore.emailAddress = response.email;
        this.ownerStore.phoneNumber = response.phone_number;
        this.ownerStore.password = ""; // Passwords are typically not pre-filled
        this.ownerStore.companyLogo = response.company.logo;
      });
    },
    async submitForm() {
      this.loading = true;
      try {
        await updateOwner(this.id, this.ownerStore);

        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data owner updated successfully",
          confirmButtonColor: "#0090e7",
        });

        this.$router.go(-1);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.errors[0],
          confirmButtonColor: "#0090e7",
        });
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    handleFileChange(event) {
      if (event.target.files.length > 0) {
        this.ownerStore.companyLogo = event.target.files[0];
      } else {
        this.ownerStore.companyLogo = this.owner.profile_picture; // Ensure it's null if no files are selected
      }
    },
  },
};
</script>
