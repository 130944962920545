import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    authToken: localStorage.getItem('authToken'),
    tokenType: localStorage.getItem('tokenType'),
    user: JSON.parse(localStorage.getItem('user'))
  },
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
      if (token === null) {
        localStorage.removeItem('authToken');
      } else {
        localStorage.setItem('authToken', token);
      }
    },
    setTokenType(state, type) {
      state.tokenType = type;
      if (type === null) {
        localStorage.removeItem('tokenType');
      } else {
        localStorage.setItem('tokenType', type);
      }
    },
    setUser(state, user) {
      state.user = user;
      if (user === null) {
        localStorage.removeItem('user');
      } else {
        localStorage.setItem('user', JSON.stringify(user));
      }
    }
  },
  actions: {
    updateAuthToken({ commit }, token) {
      commit('setAuthToken', token);
    },
    updateTokenType({ commit }, type) {
      commit('setTokenType', type);
    },
    updateUser({ commit }, user) {
      commit('setUser', user);
    }
  }
});