<template>
    <div class="row">
        <div class="page-header">
            <h3 class="page-title">Reset Password</h3>
        </div>

        <div class="col-12 grid-margin">
            <div class="card">
                <div class="card-body">
                    <form class="forms-sample" @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="form-group row">
                            <div class="col">
                                <label for="currentPassword">Current Password</label>
                                <input type="password" class="form-control" id="currentPassword"
                                    v-model="userStore.current_password" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col">
                                <label for="newPassword">New Password</label>
                                <input type="password" class="form-control" id="newPassword"
                                    v-model="userStore.new_password" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col">
                                <label for="confirmNewPassword">Confirm New Password</label>
                                <input type="password" class="form-control" id="confirmNewPassword"
                                    v-model="userStore.confirm_new_password" />
                                <div v-if="userStore.new_password !== userStore.confirm_new_password" class="text-danger" role="alert">
                                    Passwords do not match.
                                </div>
                            </div>
                        </div>

                        <div class="form-group text-right">
                            <button type="button" @click="back"
                                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3" :disabled="loading">
                                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                                Back
                            </button>
                            <button type="submit" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                                :disabled="loading || userStore.new_password !== userStore.confirm_new_password">
                                <div class="spinner-border text-warning me-3" :style="{ width: '14px', height: '14px' }"
                                    role="status" v-if="loading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <i class="mdi mdi-check-all btn-icon-prepend"></i> Update Password
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import "dropify";
import { updateProfilePassword } from "@/services/authService";
import config from "@/config/config";
import Swal from "sweetalert2";
import store from '@/store';

export default {
    name: "EditUser",
    data: () => ({
        userStore: {
            current_password: "",
            new_password: "",
            confirm_new_password: "",
        },
        id: JSON.parse(store.state.user).data.id,
        config,
        loading: false,
    }),
    mounted() {
        $(".dropify").dropify({
            messages: {
                default: "Drag and drop a file here or click",
                replace: "Drag and drop or click to replace",
                remove: "Remove",
                error: "Ooops, something wrong happened.",
            },
        });

        this.fetchDetailUser();
    },
    methods: {
        async submitForm() {
            this.loading = true;
            try {
                const response = await updateProfilePassword(this.id, this.userStore);
                console.log(response);

                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Password updated successfully",
                    confirmButtonColor: "#0090e7",
                });

                this.$router.go(-1);
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.response.data.errors[0],
                    confirmButtonColor: "#0090e7",
                });
            } finally {
                this.loading = false;
            }
        },
        back() {
            this.$router.go(-1);
        },
        // handleFileChange is not needed for password edit
    },
};
</script>