import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL; 

export const storeMaker = async (data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}user`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
};

export const getMaker = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}user/maker`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });

    return response.data;
}

export const getDetailMaker = async (id) => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}user/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const updateMaker = async (id, data) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}user/${id}`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
        }
    });
    return response.data;
}

export const deleteMaker = async (id) => {
    const token = store.state.authToken;
    const response = await axios.delete(`${API_URL}user/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const getCompany = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}company-list`, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const getSquad = async () => {
    const token = store.state.authToken;
    const response = await axios.get(`${API_URL}division`,{
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}

export const addToPlayer = async (id) => {
    const token = store.state.authToken;
    const response = await axios.post(`${API_URL}add-to-player/${id}`, null, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
    return response.data;
}