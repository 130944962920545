<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Manage Game Owner</h3>
    </div>
    <div class="col-12 grid-margin">
      <!-- <button
        type="button"
        id="searchcolumn-btn"
        class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
      </button>
      &nbsp; -->
    </div>
    <!-- <div
      class="col-lg-6 col-md-12 grid-margin"
      style="display: none"
      id="searchcolumn"
    >
      <div class="card">
        <div class="card-body">
          <div class="btn-top-right">
            <button
              id="searchcolumn-close"
              class="btn btn-dark btn-xs"
              type="button"
            >
              <i class="mdi mdi-window-close btn-icon-prepend"></i>
            </button>
          </div>
          <form class="forms-sample">
            <div class="form-group">
              <label for="exampleInputUsername1">Company Name</label>
              <input
                type="text"
                class="form-control"
                id="exampleInputUsername1"
                placeholder="All"
              />
            </div>
            <div class="form-group row">
              <div class="col">
                <label>Join Date From:</label>
                <input class="form-control" type="date" />
              </div>
              <div class="col">
                <label>Join Date To</label>
                <input class="form-control" type="date" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>Invoice Date From:</label>
                <input class="form-control" type="date" />
              </div>
              <div class="col">
                <label>Invoice Date To</label>
                <input class="form-control" type="date" />
              </div>
            </div>
            <div class="form-group">
              <label for="exampleInputPassword1">Status</label>
              <select class="form-control">
                <option>All</option>
                <option>Active</option>
                <option>Inactive</option>
              </select>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>Sort By:</label>
                <select class="form-control">
                  <option>Join Date</option>
                  <option>Player Count</option>
                  <option>Company Name</option>
                </select>
              </div>
              <div class="col">
                <label>Ordering</label>
                <select class="form-control">
                  <option>Descending</option>
                  <option>Ascending</option>
                </select>
              </div>
            </div>
            <div class="form-group text-right">
              <button
                type="button"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
              >
                <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div> -->

    <div class="col-12 grid-margin">

      <div class="card">
        <div class="card-body">

          <div class="table-responsive">
            <div class="input-group">

              <input v-model.trim="search"
              @keyup="getBilingAdminsearch" placeholder="Search"
                style="float:right;background-color:transparent;border-color:aliceblue;color:aliceblue"
                class="col-md-2" />
              <div class="input-group-append">

                <span class="input-group-text" style="border-color:aliceblue"><i class="mdi mdi-magnify"></i></span>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Company Name</th>
                  <th>Join Date</th>
                  <th>Player Count</th>
                  <th>Rules Count</th>
                  <th>Monthly Submission</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedOwner" :key="data.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    <router-link :to="{ name: 'DetailOwner', params: { id: String(data.id) } }">
                      {{ data.company.name || '-' }}
                    </router-link>
                  </td>
                  <td>{{ formatDate(data.created_at) || '-' }}</td>
                  <td class="text-center">{{ data.players_count || '-' }}</td>
                  <td class="text-center">{{ data.rulesCount || '-' }}</td>
                  <td class="text-center">{{ data.monthlySubmission || '-' }}</td>
                  <td>
                    <div
                      :class="['badge', data.status === 'active' ? 'badge-outline-success' : 'badge-outline-danger']">
                      {{ data.status ? data.status.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
                        return
                        txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                      }) : '-' }}
                    </div>
                  </td>
                </tr>
                <tr v-if="paginatedOwner.length === 0">
                  <td colspan="7" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="owner.length > 0">
            <PaginationComponents :totalItems="owner.length" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
              @page-changed="onPageChanged" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "../../config/config";
import store from "../../store";
import $ from 'jquery';
import { getOwner } from '../../services/ownerService';
import moment from 'moment';
import PaginationComponents from '../../components/PaginationComponents.vue';

export default {
  components: {
    PaginationComponents
  },
  data() {
    return {
      search: "",
      owner: [],
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {


    filteredItems() {
      return this.owner.filter(item => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },

    paginatedOwner() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.owner.slice(start, end);
    }
  },
  methods: {
    getBilingAdminsearch() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}company`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (this.search) {
            this.owner = resp.data.filter((owner) =>
            owner.name
                .toLowerCase()
                .includes(this.search.toLowerCase())
            );
          } else {
            _.owner = resp.data;
          }
        });
    }, 
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },
    fetchOwner() {
      getOwner().then(response => {
        this.owner = response;
      }).catch(error => {
        console.error('Error fetching owners:', error);
      })
    },
    onPageChanged(page) {
      this.currentPage = page;
    }
  },
  mounted() {
    this.getBilingAdminsearch()
    this.fetchOwner();
    $("#searchcolumn-btn").click(() => {
      $("#searchcolumn").show(300);
      $("#searchcolumn-btn").hide(300);
    });

    $("#searchcolumn-close").click(() => {
      $("#searchcolumn").hide(300);
      $("#searchcolumn-btn").show(300);
    });
  },
};
</script>
