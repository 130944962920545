<template>
  <div>
    <div class="row">
      <div class="page-header">
        <h3 class="page-title">Compensation Details</h3>
      </div>

      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <form class="forms-sample" method="post" action="https://dashboard.gamiteam.app/player">
              <div class="form-group row">
                <div class="col-12">
                  <label for="exampleInputUsername1">Compensation Name</label>
                  <div class="py-2">{{ compensation.name }}</div>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-12">
                  <label for="exampleInputUsername1">Description</label>
                  <div class="py-2">{{ compensation.description || '-' }}</div>
                </div>
              </div>
              
              <div class="form-group">
                <button type="button" class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 me-3" @click="back"
                  :disabled="loading">
                  <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                  Back
                </button>
                <router-link :to="{ name: 'EditCompensation' }"
                  class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2 me-3">
                  <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Edit
                  Data</router-link>
                <a class="btn btn-outline-danger btn-icon-text mt-2 px-5 py-2" @click="confirmDelete">
                  <i class="mdi mdi-delete-outline btn-icon-prepend"></i> Delete
                  Data</a>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getDetailCompensation } from "../../services/compensationService";
import { deleteCompensation } from "../../services/compensationService";
import Swal from 'sweetalert2';

export default {
  data: () => ({
    compensation: '',
    loading: false,
  }),
  props: {
    id: {
      type: String,
      required: true
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteCompensation(this.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The compensation has been deleted.',
              confirmButtonColor: '#3085d6'
            }).then(() => {
              this.$router.push({ name: 'Compensation' });
            });
          });
        }
      });
    },
    fetchCompensation() {
      getDetailCompensation(this.id).then((response) => {
        this.compensation = response.data;
      });
    },
    submitForm() {
      console.log(this.ruleStore);
    }
  },
  mounted() {
    this.fetchCompensation();
  }
};
</script>
