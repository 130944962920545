<template>
    <div class="row">
        <div class="page-header">
            <h3 class="page-title">Edit Reward Approval</h3>
        </div>

        <div class="col-12 grid-margin">
            <div class="card">
                <div class="card-body">
                    <form class="forms-sample" method="post" @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="form-group row">
                            <div class="col-12">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Submited</th>
                                            <td>{{ dateFormat(data.created_at) }}</td>
                                        </tr>
                                        <tr>
                                            <th>Rule</th>
                                            <td>{{ data.rule?.name }}</td>
                                        </tr>
                                        <tr>
                                            <th>Player</th>
                                            <td>{{ data.user?.name }}</td>
                                        </tr>
                                        <tr>
                                            <th>Reward</th>
                                            <td>+{{ data.rule?.gold }} Gold</td>
                                        </tr>
                                        <tr>
                                            <th>Description</th>
                                            <td>
                                                {{ data.rule?.description ?? '-' }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Comment</th>
                                            <td>
                                                {{ data.comment ?? '-' }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Attachment</th>
                                            <td>
                                                <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#attachmentModal">
                                                    View Attachment
                                                </button>

                                                <!-- Modal -->
                                       
                                            </td>
                                        </tr>
                                        <tr v-if="data.status === 'rejected'">
                                            <th>Rejection Notes</th>
                                            <td>{{ data.rejection_notes ?? '-' }}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>
                                                <span class="badge badge-warning" v-if="data.status === 'pending'">Pending</span>
                                                <span class="badge badge-success" v-else-if="data.status === 'approved'">Approved</span>
                                                <span class="badge badge-danger" v-else>Rejected</span>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                        <div class="modal fade" id="attachmentModal" tabindex="-1" aria-labelledby="attachmentModalLabel" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="attachmentModalLabel">Attachment</h5>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">
                                                                <div class="container">
                                                                    <img :src="config.STORAGE_URL + (data && data.evidence)" width="100%">
                                                                </div>
                                                             
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                        <div class="form-group row" v-if="data.status == 'pending'">
                            <div class="col-12">
                                <label for="assignedToInput">Status</label>
                                <select class="form-control" id="assignedToInput" v-model="status">
                                    <option value="approved">Approve</option>
                                    <option value="rejected">Rejected</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row" v-if="status === 'rejected' && data.status == 'pending'">
                            <div class="col-12">
                                <label for="rejectionNotes">Rejection Notes</label>
                                <textarea class="form-control" id="rejectionNotes" v-model="rejection_notes"></textarea>
                            </div>
                        </div>

                        <div class="form-group text-right">
                            <button type="button" @click="back"
                                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 mx-3" :disabled="loading">
                                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                                Back
                            </button>
                            <button v-if="data.status == 'pending'" type="submit" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2"
                                :disabled="loading">
                                <div class="spinner-border text-warning me-3" :style="{ width: '14px', height: '14px' }"
                                    role="status" v-if="loading">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                                <i class="mdi mdi-check-all btn-icon-prepend"></i> Submit Data
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import "dropify";
import { updateRewardApproval } from "../../services/approvalService";
import Swal from 'sweetalert2';
import { getDetailRewardApproval } from "../../services/approvalService";
import moment from "moment";
import config from '@/config/config';

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        loading: false,
        status: 'approve',
        data: {},
        rejection_notes: '',
        config
    }),
    watch: {
        'status': function () {
            this.rejection_notes = '';
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format('DD MMM YYYY');
        },
        async fetchData() {
            const res = await getDetailRewardApproval(this.id);
            this.data = res.data;
        },
        back() {
            this.$router.go(-1);
        },
        async submitForm() {
            this.loading = true;
            try {
                await updateRewardApproval(this.id, this.status, this.rejection_notes);
                Swal.fire({
                    title: 'Success',
                    text: 'Data has been submitted',
                    icon: 'success'
                });
                this.back();
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Failed to submit data',
                    icon: 'error'
                });
                console.error('Error submitting form:', error);
            } finally {
                this.loading = false;
            }
        }
    }, 
    mounted() {
        this.fetchData();
    }
};
</script>

<style>
ul.custom-list {
    list-style-type: none;
    padding: 0;
}

ul.custom-list li {
    margin: 20px 0;
    display: flex;
    align-items: center;
}

ul.custom-list li input[type="checkbox"],
ul.custom-list li input[type="radio"] {
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    transform: scale(1);
}

ul.custom-list li span {
    font-size: 1.2em;
}

.grouping {
    background-color: #282e3e;
    padding: 10px;
    border-radius: 10px;
}
.img{
    width: 100%;
}
</style>

