<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Manage Game player</h3>
    </div>
    <div class="col-12 grid-margin">
      <!-- <button
        type="button"
        id="searchcolumn-btn"
        class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
      >
        <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
      </button>
      &nbsp; -->
      <router-link :to="{ name: 'CreatePlayer' }" class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2">
        <i class="mdi mdi-plus btn-icon-prepend"></i> Insert new Data
      </router-link>
    </div>
    <!-- <div
      class="col-lg-6 col-md-12 grid-margin"
      style="display: none"
      id="searchcolumn"
    >
      <div class="card">
        <div class="card-body">
          <div class="btn-top-right">
            <button
              id="searchcolumn-close"
              class="btn btn-dark btn-xs"
              type="button"
            >
              <i class="mdi mdi-window-close btn-icon-prepend"></i>
            </button>
          </div>
          <form class="forms-sample">
            <div class="form-group">
              <label for="exampleInputUsername1">Company </label>
              <select
                class="js-example-basic-single form-control"
                style="width: 100%"
                data-select2-id="1"
                tabindex="-1"
                aria-hidden="true"
              >
                <option v-for="option in companyOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div class="form-group row">
              <div class="col">
                <label>Name</label>
                <input class="form-control" type="text" placeholder="All" />
              </div>
              <div class="col">
                <label>Email</label>
                <input class="form-control" type="text" placeholder="All" />
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label>Sort By:</label>
                <select class="form-control">
                  <option>Join Date</option>
                  <option>Name</option>
                  <option>Company Name</option>
                </select>
              </div>
              <div class="col">
                <label>Ordering</label>
                <select class="form-control">
                  <option>Descending</option>
                  <option>Ascending</option>
                </select>
              </div>
            </div>
            <div class="form-group text-right">
              <button
                type="button"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2"
              >
                <i class="mdi mdi-magnify btn-icon-prepend"></i> Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div> -->

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <div class="input-group">

              <input    v-model.trim="search"
              @keyup="getBilingAdminsearch" placeholder="Search"
                style="float:right;background-color:transparent;border-color:aliceblue;color:aliceblue"
                class="col-md-2" />
              <div class="input-group-append">

                <span class="input-group-text" style="border-color:aliceblue"><i class="mdi mdi-magnify"></i></span>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Company</th>
                  <th>Join Date</th>
                  <th>Squad</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in paginatedPlayer" :key="data.id">
                  <td>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
                  <td>
                    <router-link :to="{ name: 'DetailPlayer', params: { id: data.id } }">
                      {{ data.name || '-' }}
                    </router-link>
                  </td>
                  <td>{{ data.email || '-' }}</td>
                  <td>
                    {{ data.division ? data.division.name : '-' }}
                  </td>
                  <td>{{ formatDate(data.created_at) || '-' }}</td>
                  <td>{{ data.division.name || '-' }}</td>
                  <td>
                    <button v-if="data.role === 'player'" @click="addToMaker(data.id)"
                      class="btn btn-outline-info btn-icon-text mt-2 py-2">
                      Add to Maker
                    </button>
                    <span v-if="data.role === 'maker,player'" class="badge badge-primary">Already in Maker</span>
                  </td>
                </tr>
                <tr v-if="paginatedPlayer.length === 0">
                  <td colspan="7" class="text-center">No data available</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="player.length > 0">
            <PaginationComponents :totalItems="player.length" :itemsPerPage="itemsPerPage" :currentPage="currentPage"
              @page-changed="onPageChanged" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { getCompany } from "../../services/playerService";
import { getPlayer } from "../../services/playerService";
import { addToMaker } from "../../services/playerService";
import moment from 'moment';
import PaginationComponents from '../../components/PaginationComponents.vue';
import swal from 'sweetalert2';
import axios from "axios";
import config from "../../config/config";
import store from "../../store";

export default {
  components: {
    PaginationComponents
  },
  data() {
    return {
      search: "",
      companyOptions: [],
      player: [],
      currentPage: 1,
     itemsPerPage: 10,
      mountedDone: false,
    };
  },
  computed: {
    filteredItems() {
      return this.player.filter(item => {
        return item.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
    paginatedPlayer() {
      if (this.mountedDone) {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.player.length > 0 ? this.player.slice(start, end) : [];
      } else {
        return [];
      }
    },
  },
  methods: {
    getBilingAdminsearch() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;

      let _ = this;
      axios
        .get(`${API_URL}user/player`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((resp) => {
          if (this.search) {
            this.player = resp.data.data.filter((player) =>
            player.name
                .toLowerCase()
                .includes(this.search.toLowerCase())
            );
          } else {
            _.player = resp.data.data;
          }
        });
    }, 
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },
    async fetchData() {
      await getCompany().then((response) => {
        response.forEach((item) => {
          this.companyOptions.push({
            value: item.id,
            label: item.company_name,
          });
        });
      });

      await getPlayer().then((response) => {
        this.player = response.data;
      }).catch(error => {
        console.error('Error fetching players:', error);
      })
    },
    onPageChanged(page) {
      this.currentPage = page;
    },
    async addToMaker(id) {
      swal.fire({
        title: 'Are you sure?',
        text: "Do you want to add this to maker?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, add it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await addToMaker(id).then(() => {
            window.location.reload();
          }).catch(error => {
            console.error('Error adding to maker:', error);
          });
        }
      });
    }
  },
  async mounted() {
    this.getBilingAdminsearch()
    await this.fetchData().then(() => {
      this.mountedDone = true;
    });
    $("#searchcolumn-btn").click(() => {
      $("#searchcolumn").show(300);
      $("#searchcolumn-btn").hide(300);
    });

    $("#searchcolumn-close").click(() => {
      $("#searchcolumn").hide(300);
      $("#searchcolumn-btn").show(300);
    });
  },
};
</script>
