<template>
    <div class="row">
      <div class="page-header">
        <h3 class="page-title">Game Payment Details</h3>
      </div>
  
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <form
              class="forms-sample"
              method="post"
              action="https://dashboard.gamiteam.app/payment"
            >
              <div class="form-group row">
                <div class="col">
                  <label for="exampleInputUsername1">Name</label>
                  <div class="py-2">{{ payment.name }}</div>
                </div>
                <div class="col">
                  <label></label>
                </div>
              </div>
  
              <div class="form-group row">
                <div class="col">
                  <label for="exampleInputUsername1">Payment Name</label>
                  <div class="py-2">{{ formatRupiah(payment.price) }}</div>
                </div>
                <div class="col">
                  <label>Payment Status</label>
                  <div class="py-2">{{ payment.status }}</div>
                </div>
              </div>
  
              
              <div class="form-group">
                <button
                  type="button"
                  @click="back"
                  class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 me-3"
                >
                  <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                  Back
                </button>
                <router-link
                  :to="{name:'EditPayment', params: { id: payment.id }}"
                  class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2 me-3"
                >
                  <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Edit
                  Data</router-link
                >
                <a @click="confirmDelete"
                  class="btn btn-outline-danger btn-icon-text mt-2 px-5 py-2"
                >
                  <i class="mdi mdi-delete-outline btn-icon-prepend"></i> Delete
                  Data</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { getDetailPayment } from '@/services/paymentService';
  import config from '@/config/config';
  import Swal from 'sweetalert2';
  import { deletePayment } from '@/services/paymentService';
  
  export default {
    props: {
      id: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        payment: '',
        config
      }
    },
    mounted() {
      this.fetchDetailPayment();
    },
    methods: {
      fetchDetailPayment() {
        getDetailPayment(this.id).then(response => {
          this.payment = response;
        });
      },
      formatRupiah(number) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(number);
      },
      back() {
        this.$router.go(-1);
      },
      confirmDelete() {
        Swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            deletePayment(this.id).then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'The payment has been deleted.',
                confirmButtonColor: '#3085d6'
              }).then(() => {
                this.$router.push({ name: 'ListPayment' });
              });
            });
          }
        });
      },
    },
  };
  </script>
  
  