<template>
  <div class="row">
    <div class="page-header">
      <h3 class="page-title">Game maker Details</h3>
    </div>

    <div class="col-12 grid-margin">
      <div class="card">
        <div class="card-body">
          <form
            class="forms-sample"
            method="post"
            action="https://dashboard.gamiteam.app/maker"
          >
            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Maker Name</label>
                <div class="py-2">{{ maker.name }}</div>
                <div class="py-2">
                  <img width="100" :src="maker.profile_picture ? config.STORAGE_URL + maker.profile_picture : require('../../assets/images/faces/default.jpg')" />
                </div>
              </div>
              <div class="col">
                <label></label>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Join Date</label>
                <div class="py-2">{{ formatDate(maker.created_at) }}</div>
              </div>
              <div class="col">
                <label>Squad</label>
                <div class="py-2">{{ maker && maker.division.name }}</div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col">
                <label for="exampleInputUsername1">Email</label>
                <div class="py-2">{{ maker.email }}</div>
              </div>
              <div class="col">
                <label>Phone Number</label>
                <div class="py-2">{{ maker.phone_number || '-' }}</div>
              </div>
            </div>

            <div class="form-group">
              <button
                type="button"
                @click="back"
                class="btn btn-outline-info btn-icon-text mt-2 px-5 py-2 me-3"
              >
                <i class="mdi mdi-subdirectory-arrow-left btn-icon-prepend"></i>
                Back
              </button>
              <router-link
                :to="{name:'EditMaker', params: { id: maker.id }}"
                class="btn btn-outline-warning btn-icon-text mt-2 px-5 py-2 me-3"
              >
                <i class="mdi mdi-lead-pencil btn-icon-prepend"></i> Edit
                Data</router-link
              >
              <a @click="confirmDelete"
                class="btn btn-outline-danger btn-icon-text mt-2 px-5 py-2"
              >
                <i class="mdi mdi-delete-outline btn-icon-prepend"></i> Delete
                Data</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { getDetailMaker } from '@/services/makerService';
import config from '@/config/config';
import Swal from 'sweetalert2';
import { deleteMaker } from '@/services/makerService';

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      maker: '',
      config
    }
  },
  mounted() {
    this.fetchDetailMaker();
  },
  methods: {
    fetchDetailMaker() {
      getDetailMaker(this.id).then(response => {
        this.maker = response.data;
      });
    },
    formatDate(date) {
      return moment(date).format('DD MMMM YYYY');
    },
    back() {
      this.$router.go(-1);
    },
    confirmDelete() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMaker(this.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The maker has been deleted.',
              confirmButtonColor: '#3085d6'
            }).then(() => {
              this.$router.push({ name: 'ListMaker' });
            });
          });
        }
      });
    },
  },
};
</script>

