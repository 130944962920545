import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL; 

export const getReward = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}rule`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const storeReward = async (reward) => {
  const token = store.state.authToken;
  const response = await axios.post(`${API_URL}rule`, reward, { headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" } });
  console.log(response);
  return response.data;
};

export const getDetailReward = async (id) => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}rule/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const deleteReward = async (id) => {
  const token = store.state.authToken;
  const response = await axios.delete(`${API_URL}rule/${id}`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const updateReward = async (id, data) => {
  const token = store.state.authToken;
  const response = await axios.put(`${API_URL}rule/${id}`, data, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const getUser = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}user`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};

export const getDivision = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}division`, { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
};


