<template>
  <div id="app">
    <!-- Render different layout based on the current route -->
    <div v-if="isLoginRoute">
      <router-view/>
    </div>
    <div v-else-if="isForgotPassword">
      <router-view/>
    </div>
    <div v-else>
      <div class="container-scroller">
        <Sidebar />
        <div class="container-fluid page-body-wrapper">
          <Navbar />
          <div class="main-panel">
            <div class="content-wrapper">
              <router-view/>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/SidebarComponents.vue';
import Navbar from './components/NavbarComponents.vue';
import Footer from './components/FooterComponents.vue';
import 'bootstrap';

export default {
  created() {
    document.title = 'Gamiteam Dashboard';
  },
  components: {
    Sidebar,
    Navbar,
    Footer
  },
  computed: {
    isLoginRoute() {
        // Check if the current route is the login route
        return this.$route.path === '/login';
    },
    isForgotPassword(){
      return this.$route.path === '/forgotpassword';
    }
  },
}
</script>

<style>
@import url('assets/style.css');
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.x.x/css/materialdesignicons.min.css');
@import url('assets/vendors/css/vendor.bundle.base.css');
/* @import url('assets/vendors/mdi/css/materialdesignicons.min.css'); */

@import url('assets/vendors/select2/select2.min.css');
@import url('assets/vendors/select2-bootstrap-theme/select2-bootstrap.min.css');

@import url('dropify/dist/css/dropify.min.css');
@import url('assets/css/modern-vertical/style.css'); 

input[type="date"]::-webkit-calendar-picker-indicator {
  background-color: white;
}
</style>
