<template>
  <div class="container-fluid container-login">
    <div class="content-wrapper">
      <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="card login">
            <div class="card-header text-center">
              <img
                width="190"
                class="mx-auto"
                src="../assets/images/logo-big.svg"
                alt=""
              />
            </div>
            <div class="card-body px-5 pt-5">
              <h5 class="card-title mb-2 text-center">User Authentication</h5>
              <br />
              <form class="form-login" @submit.prevent="handleLogin">
                <div class="form-group mb-3">
                  <label for="email" class="form-label">Email *</label>
                  <input
                    class="form-control"
                    type="text"
                    id="email"
                    v-model="credentials.email"
                    required
                  />
                </div>
                <div class="form-group mb-4">
                  <label for="password" class="form-label">Password *</label>
                  <input
                    class="form-control"
                    type="password"
                    id="password"
                    v-model="credentials.password"
                    required
                  />
                </div>
                <div class="text-center mb-3">
                  <button
                    type="submit"
                    class="btn btn-login px-3"
                    :disabled="loading"
                  >
                    <div
                      class="spinner-border text-light me-2"
                      role="status"
                      v-if="loading"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </div>
                    <i class="mdi mdi-key me-1"></i> Login
                  </button>
                </div>
                <div class="text-center forgot-password">
                  <a href="#" @click="getforgot">Forgot Password?</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isModalOpen" class="custom-modal">
      <div class="custom-modal-content custom-modal-centered costom-modal-sm">
        <div class="custom-modal-body text-centx    er">
          <h3 class="mb-0">Enter your email address</h3>
        
          <input
            type="email"
            v-model="email"
            class="form-control"
            name=""
            id=""
            required
          />
        </div>
        <div class="custom-modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">
            Close
          </button>

          <button type="button" class="btn btn-primary" @click="proses_forgot_password">Forgot Password</button>
          
        </div>
        
      </div>
      
    </div>
  </div>
</template>

<script>
import { login } from "@/services/authService";
import { forgotPasswordAPI } from "@/services/authService";
import Swal from "sweetalert2";
import axios from "axios";
import config from "../config/config";
import store from "../store";
export default {
  name: "LoginView",
  data: () => ({
    credentials: {
      email: "",
      password: "",
    },
    email: "",
    isModalOpen: false,
    loading: false,
  }),
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    getforgot() {
      this.isModalOpen = true;
    },
    async handleLogin() {
      this.loading = true;
      try {
        const response = await login(this.credentials);
        // Replace local storage with Vuex mutations
        this.$store.dispatch("updateAuthToken", response.data.token);
        this.$store.dispatch("updateTokenType", response.data.token_type);

        this.$router.push("/dashboard");
      } catch (error) {
        if (error.response.data.errors.password) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to login. " + error.response.data.errors.password,
            confirmButtonColor: "#0090e7",
          });
        } else if (error.response.data.errors.email) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Failed to login. " + error.response.data.errors.email,
            confirmButtonColor: "#0090e7",
          });
        }
      } finally {
        this.loading = false;
      }
    },
    handleForgotPassword() {
      // Implement the logic to handle forgotten password
      Swal.fire({
        title: "Enter your email address",
        input: "email",
        inputLabel: "Email",
        inputPlaceholder: "Enter your email address",
        showCancelButton: true,
        confirmButtonColor: "#0090e7", // Changed button color to a red shade
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: (email) => {
          // Call the forgot password service
          return this.forgotPassword(email);
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    proses_forgot_password() {
      const API_URL = config.API_URL;
      const token = store.state.authToken;
      let formData = new FormData();
      formData.append("email", this.email);
  
      formData.append("_method", "POST");

      
      axios
        .post(`${API_URL}auth/forgot-password`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
            Swal.fire({
            icon: "success",
            title: "Please check your email",
            text:  resp.data.message,
          });
          console.log(resp);
        
      
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No account found with that email. Please try again."
          });
          console.log(err);
        });
    },
    async forgotPassword(email) {
      const response = await forgotPasswordAPI(email);
      if (response.error === "Password Exist") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "No account found with that email. Please try again.",
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Please check your email",
          text: "A link to reset your password has been sent to " + email,
        });
      }
    },
  },
};
</script>
