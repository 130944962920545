import axios from "axios";
import config from "../config/config";
import store from "../store";

const API_URL = config.API_URL; 

export const countMaker = async () => {
  const token = store.state.authToken;
  const role = "maker";
  const response = await axios.get(`${API_URL}user/count`, { 
    params: { role },
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export const countPlayer = async () => {
  const token = store.state.authToken;
  const role = "player";
  const response = await axios.get(`${API_URL}user/count`, { 
    params: { role },
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export const countRule = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}rule/count`, { 
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export const countUser = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}user/count-all`, { 
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export const countCompany = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}company/count`, { 
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};

export const countSubmissionRule = async () => {
  const token = store.state.authToken;
  const response = await axios.get(`${API_URL}user-rule/count-all`, { 
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
};